import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, Form, Spinner } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import { fetchData, academics } from "../../services/fetchData";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { LoaderListPage } from "../../pages/Loader/Loader";
import { FilterDropStyle } from "../../Style/dropStyle";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  onPostTypeDropselect,
  onChannelDropselect,
  onModuleDropselect,
  onContentChannelDropselect,
  onServiceDropselect,
  onSearchInputData,
  isLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const location = useLocation();
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const [postDropData, setPostDropData] = useState([]);
  const [postSelectedValue, setPostSelectedValue] = useState("");

  const [channelDropData, setchannelDropData] = useState([]);
  const [channelSelectedValue, setChannelSelectedValue] = useState("");

  const [moduleDropData, setModuleDropData] = useState([]);
  const [moduelSelectedValue, setModuelSelectedValue] = useState("");

  const [contentChannelDropData, setContentChannelDropData] = useState([]);
  const [contentChannelSelectedValue, setContentChannelSelectedValue] =
    useState("");

  const [serviceDropData, setserviceDropData] = useState([]);
  const [serviceSelectedValue, setServiceSelectedValue] = useState("");

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getPostTypeData();
    getChannelData();
    getModulData();
    getManageChannelData();
    getServiceData();
  }, []);

  // geting filter drop data
  const getPostTypeData = async () => {
    try {
      let payload = {
        modelName: "post_type_masters",

        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        setPostDropData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getChannelData = async () => {
    try {
      const payload = {
        modelName: "academic_channels",
        whereCondition: {
          type: 1,
          is_active: 1,
        },
      };
      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );
      if (responseData.code == 1) {
        setchannelDropData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getModulData = async (mapChannelValues) => {
    try {
      const payload = {
        type: 1,
        channel_id: mapChannelValues,
      };
      const responseData = await fetchData(
        "modulelistbytype",
        payload,
        academics
      );
      if (responseData.code == 1) {
        setModuleDropData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getManageChannelData = async () => {
    try {
      const payload = {
        modelName: "academic_channels",
        whereCondition: {
          type: 2,
          is_active: 1,
        },
      };
      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );
      if (responseData.code == 1) {
        setContentChannelDropData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getServiceData = async () => {
    try {
      const payload = {
        modelName: "service_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        setserviceDropData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // on Filter data select
  const onPostFilterChangeSelect = async (value) => {
    setPostSelectedValue(value);
    onPostTypeDropselect(value.value);
  };

  const onChannelFilterChangeSelect = async (value) => {
    let mapChannelValues = value.map((item, index) => item.value);
    setChannelSelectedValue(mapChannelValues);
    onChannelDropselect(mapChannelValues);
    getModulData(mapChannelValues);
  };

  const onModuleFilterChangeSelect = async (value) => {
    let mapModuleValues = value.map((item, index) => item.value);
    setModuelSelectedValue(mapModuleValues);
    onModuleDropselect(mapModuleValues);
  };

  const onContentChannelFilterChangeSelect = async (value) => {
    let mapContyentChannelValues = value.map((item, index) => item.value);
    setContentChannelSelectedValue(mapContyentChannelValues);
    onContentChannelDropselect(mapContyentChannelValues);
  };

  const onServiceFilterChangeSelect = async (value) => {
    let mapServiceValues = value.map((item, index) => item.value);
    setServiceSelectedValue(mapServiceValues);
    onServiceDropselect(mapServiceValues);
  };

  // getting filter drop options data
  const postTypeOptions = postDropData.map((item) => ({
    value: item.id,
    label: item.type_name,
  }));

  const channelOptions = channelDropData.map((item) => ({
    value: item.id,
    label: item.channel_title,
  }));

  const moduleOptions = moduleDropData.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const contentChannelOptions = contentChannelDropData.map((item) => ({
    value: item.id,
    label: item.channel_title,
  }));

  const serviceOptions = serviceDropData.map((item) => ({
    value: item.id,
    label: item.service_name,
  }));

  // handle search
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    onSearchInputData(value);
  };

  // search page route
  const isSearchableRoute = [
    "/tenant-user-list",
    "/cms-user-list",
    "/ibo-user-list",
    "/guest-user-list",
    "/academic-channels-list",
    "/academic-module-list",
    "/academic-post-list",
    "/content-channel-list",
    "/content-post-list",
  ].includes(location.pathname);

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1} style={{ width: "140px" }}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}>
            {[10, 20, 30, 40, 50,100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}>
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}

        {location.pathname === "/post-sub-type-list" && (
          <Col md="2" style={{ paddingLeft: "5px", marginRight: "40px" }}>
            <Select
              value={
                postSelectedValue && postSelectedValue.value
                  ? postTypeOptions.find(
                      (option) => option.value === postSelectedValue.value
                    )
                  : ""
              }
              onChange={(selectedOption) =>
                onPostFilterChangeSelect(selectedOption ? selectedOption : "")
              }
              options={postTypeOptions}
              placeholder="Select Post"
              classNamePrefix="react-select"
              isClearable
              // isMulti={true}
              noOptionsMessage={() => "no data found.."}
              maxMenuHeight={200}
              styles={FilterDropStyle}
            />
          </Col>
        )}

        {location.pathname === "/academic-post-list" && (
          <>
            <Col md="2" style={{ paddingLeft: "5px", marginRight: "40px" }}>
              <Select
                value={channelOptions.find(
                  (option) => option.value === channelSelectedValue
                )}
                onChange={(selectedOption) =>
                  onChannelFilterChangeSelect(
                    selectedOption ? selectedOption : null
                  )
                }
                options={channelOptions}
                placeholder="Select Channel"
                classNamePrefix="react-select"
                isClearable
                isMulti={true}
                noOptionsMessage={() => "no data found.."}
                maxMenuHeight={200}
                styles={FilterDropStyle}
              />
            </Col>
            <Col md="2" style={{ paddingLeft: "5px" }}>
              <Select
                value={moduleOptions.find(
                  (option) => option?.value === moduelSelectedValue
                )}
                onChange={(selectedOption) =>
                  onModuleFilterChangeSelect(
                    selectedOption ? selectedOption : null
                  )
                }
                options={moduleOptions}
                placeholder="Select Module"
                classNamePrefix="react-select"
                isClearable
                isMulti={true}
                noOptionsMessage={() => "no data found.."}
                maxMenuHeight={200}
                styles={FilterDropStyle}
              />
            </Col>
          </>
        )}

        {location.pathname === "/academic-module-list" && (
          <Col md="2" style={{ paddingLeft: "5px", marginRight: "40px" }}>
            <Select
              value={channelOptions.find(
                (option) => option.value === channelSelectedValue
              )}
              onChange={(selectedOption) =>
                onChannelFilterChangeSelect(
                  selectedOption ? selectedOption : null
                )
              }
              options={channelOptions}
              placeholder="Select Channel"
              classNamePrefix="react-select"
              isClearable
              isMulti={true}
              noOptionsMessage={() => "no data found.."}
              maxMenuHeight={200}
              styles={FilterDropStyle}
            />
          </Col>
        )}

        {location.pathname === "/content-post-list" && (
          <Col md="3">
            <Select
              value={contentChannelOptions.find(
                (option) => option.value === contentChannelSelectedValue
              )}
              onChange={(selectedOption) =>
                onContentChannelFilterChangeSelect(
                  selectedOption ? selectedOption : null
                )
              }
              options={contentChannelOptions}
              placeholder="Select Channel"
              classNamePrefix="react-select"
              isClearable
              isMulti={true}
              noOptionsMessage={() => "no data found.."}
              maxMenuHeight={200}
              styles={FilterDropStyle}
            />
          </Col>
        )}

        {location.pathname === "/feature-list" && (
          <Col md="2" style={{ paddingLeft: "5px", marginRight: "40px" }}>
            <Select
              value={serviceOptions.find(
                (option) => option.value === serviceSelectedValue
              )}
              onChange={(selectedOption) =>
                onServiceFilterChangeSelect(
                  selectedOption ? selectedOption : null
                )
              }
              options={serviceOptions}
              placeholder="Select Service"
              classNamePrefix="react-select"
              isClearable
              isMulti={true}
              noOptionsMessage={() => "no data found.."}
              maxMenuHeight={200}
              styles={FilterDropStyle}
            />
          </Col>
        )}

        {isSearchableRoute && (
          <Col className="ms-auto" md="3">
            <Form className="app-search d-flex d-lg-inline mt-0">
              <div className="position-relative">
                <Input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  value={searchInput}
                  onChange={handleSearchChange}
                />
                <span className="ri-search-line"></span>
              </div>
            </Form>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table mt-4">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* {console.log("column===>>>>>>>", column)} */}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <LoaderListPage />
            ) : data.length > 0 ? (
              <>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "95%",
                }}>
                <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                  No data found..!
                </h4>
              </div>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}>
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}>
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
