import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkLogin, apiError } from "../../store/actions";
import { fetchData, user } from "../../services/fetchData";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginError = useSelector((state) => state.Login.loginError);

  useEffect(() => {
    dispatch(apiError(""));
    document.body.classList.add("auth-body-bg");

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, [dispatch]);

  // const handleSubmit = useCallback(
  //   (event, values) => {
  //     dispatch(checkLogin(values, navigate));
  //   },
  //   [dispatch, navigate]
  // );

 

  const handleSubmit = async (event, values) => {
    if (!username || !password) {
      return toast.warning("All fields are requred!");
    }

    try {
      const payload = {
        modelName: "cms_user_masters",
        username: values.username,
        password: values.password,
      };

      const responseData = await fetchData("auth/login", payload, user);

      if (responseData.status === 201 && responseData.code === 1) {
        localStorage.setItem("logedin-user", JSON.stringify(responseData.data));
        toast.success(
          `${responseData.data[0].first_name} ${responseData.message}`
        );
        navigate("/dashboard");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logo}
                                alt=""
                                height="20"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logo}
                                alt=""
                                height="100"
                                width="100"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>
                          {/* <h4 className="font-size-18 mt-4">Welcome Back!</h4> */}
                        </div>
                        {loginError ? (
                          <Alert color="danger">{loginError}</Alert>
                        ) : null}
                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Username</Label>
                              <AvField
                                name="username"
                                value={username}
                                type="text"
                                className="form-control"
                                id="username"
                                // validate={{ required: true }}
                                errorMessage="Enter Username"
                                validate={{
                                  required: { value: true },
                                }}
                                placeholder="Enter username"
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </div>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                // validate={{ required: true }}
                                errorMessage="Enter Password"
                                validate={{
                                  required: { value: true },
                                }}
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            {/* <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customControlInline">
                                Remember me
                              </Label>
                            </div> */}
                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit">
                                Log In
                              </Button>
                            </div>
                            {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted">
                                <i className="mdi mdi-lock me-1"></i> Forgot
                                your password?
                              </Link>
                            </div> */}
                          </AvForm>
                        </div>
                        {/* <div className="mt-5 text-center">
                          <p>
                            Don't have an account?{" "}
                            <Link
                              to="/register"
                              className="fw-medium text-primary">
                              {" "}
                              Register{" "}
                            </Link>
                          </p>
                          <p>
                            © 2021 Nazox. Crafted with{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            Themesdesign
                          </p>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
