import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";
import { showNormalToast } from "../../../services/toaster/NormalToaster";

const breadcrumbItems = [];

const AcademicCategoryList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      const payload = {
        modelName: "category_masters",
        relations: [
          {
            module: "tenant_masters",
            moduleas: "tenant_masters",
          },
        ],
        whereCondition: {
          type: 1,
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      // Assign an `index` property starting from 1
      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/academic-category-create");
  };

  const handleEditClick = (id) => {
    navigate(`/academic-category-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload1 = {
        modelName: "academic_channel_categories",
        whereCondition: {
          category_id: id,
        },
      };
      const isCategoryExist = await fetchData(
        "getMasterList",
        payload1,
        academics
      );
      if (
        isCategoryExist &&
        isCategoryExist.data &&
        isCategoryExist.data.length > 0
      ) {
        showNormalToast("This Category Already Assigned to the Channel");
        isToastOpenRef.current = false;
        return false;
      } else {
        const payload = {
          id: id,
          modelName: "category_masters",
          inputData: {
            tenant_id: 1,
            is_deleted: 1,
          },
        };

        const responseData = await fetchData("createAndUpdateMaster", payload);
        if (responseData && responseData.code === 1) {
          toast.success("Category Deleted!");
          getData(); // Refres  h data after deletion
        } else {
          toast.error("Error while deleting the Category.");
        }
        isToastOpenRef.current = false;
      }
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };
    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Category?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },
      {
        Header: "Tenant Name",
        accessor: (row) =>
          row.tenant_masters && row.tenant_masters.tenant_company_name
            ? row.tenant_masters.tenant_company_name
            : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "200px" }}>{value}</div>,
      },
      {
        Header: "Category Name",
        accessor: (row) =>
          row.category_name && row.category_name ? row.category_name : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "300px" }}>{value}</div>,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        width: 100,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Academic Category List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Academic Category
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicCategoryList;
