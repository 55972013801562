import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const optionSelect = [
  {
    options: [
      { label: "ibo", value: 1 },
      { label: "guest", value: 2 },
    ],
  },
];

const PrivacyPolicyCreate = () => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);

  const [countrySelectedDropdown, setCountrySelectedDropdown] = useState(null);
  const [countryList, setCountrylist] = useState([]);
  const [optionSelectDropdown, setOptionSelectDropdown] = useState(null);

  const [countryError, setCountryError] = useState(false);
  const [optionError, setOptionError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    tenant_id: "",
    country_id: "",
    privacy_policy: "",
    type: "",
    is_global: 0,
    is_deleted: 0,
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      getCountryDropdownData();
      let payload = {
        modelName: "privacy_policy_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          getCountryDropdownData(data.country_id);
          const selectedOptionType = optionSelect[0].options.find(
            (option) => option.value === data.type
          );
          if (selectedOptionType) {
            setOptionSelectDropdown(selectedOptionType);
          }
          setFormData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code === 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCountryDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "country_masters",
        // whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "country_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setCountrySelectedDropdown(element);
            }
          });
        }
        setCountrylist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      tenant_id: selectedOption ? selectedOption.value : "",
      is_global: 0,
    }));
  };

  const handleCountrySelectChange = (countrySelectedDropdown) => {
    setCountrySelectedDropdown(countrySelectedDropdown);
    setCountryError(!countrySelectedDropdown);
    setFormData((prevState) => ({
      ...prevState,
      country_id: countrySelectedDropdown ? countrySelectedDropdown.value : "",
    }));
  };

  const editorDataRef = useRef(formData.terms_and_condition);

  const handleEditorChange = (event, editor) => {
    let data = editor.getData();

    // Ensure all text has a default color
    const tagsToStyle = [
      "p", "h1", "h2", "h3", "h4", "h5", "h6",
      "span", "div", "ul", "ol", "li"
    ];

    tagsToStyle.forEach((tag) => {
      const regex = new RegExp(`<${tag}(?![^>]*style=)`, "g");
      data = data.replace(regex, `<${tag} style="color: black;"`);
    });

    // Update the ref with the new data without re-rendering the component
    editorDataRef.current = data;
  };
  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleOptionSelectChange = (optionSelectDropdown) => {
    setOptionSelectDropdown(optionSelectDropdown);
    setOptionError(!optionSelectDropdown);
    setFormData((prevState) => ({
      ...prevState,
      type: optionSelectDropdown ? optionSelectDropdown.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.privacy_policy = editorDataRef.current;

    if (!selectedTenant || !countrySelectedDropdown || !optionSelectDropdown) {
      setTenantError(true);
      setCountryError(true);
      setOptionError(true);
    } else {
      setTenantError(false);
      setCountryError(false);
      setOptionError(false);
    }

    if (
      !selectedTenant ||
      !countrySelectedDropdown ||
      !optionSelectDropdown ||
      !formData.privacy_policy
    ) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);

      var payload = {
        modelName: "privacy_policy_masters",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Privacy and Policy saved successfully!");
        navigate("/privacy-policy-list");
      } else {
        toast.error("Error while saving Privacy and Policy!");
        navigate("/privacy-policy-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE PRIVACY POLICY"
            breadcrumbItems={breadcrumbItems}
          />
          <div style={formContentContainer}>
            <AvForm className="needs-validation">
              <>
                {" "}
                <Row>
                  <Col sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Tenant
                        <Asterisk />
                      </Label>
                      <Select
                        value={selectedTenant}
                        onChange={handleSelectTenantChange}
                        options={tenantlist}
                        classNamePrefix="select2-selection"
                        noOptionsMessage={() => "no data found.."}
                        maxMenuHeight={200}
                        isClearable={true}
                      />
                      {tenantError && (
                        <span>
                          <p
                            style={{
                              fontSize: "11.5px",
                              color: "red",
                            }}>
                            Select Tenant
                          </p>
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Country
                        <Asterisk />
                      </Label>
                      <Select
                        value={countrySelectedDropdown}
                        onChange={handleCountrySelectChange}
                        options={countryList}
                        classNamePrefix="select2-selection"
                        noOptionsMessage={() => "no data found.."}
                        maxMenuHeight={200}
                        isClearable
                      />
                      {countryError && (
                        <span>
                          <p
                            style={{
                              fontSize: "11.5px",
                              color: "red",
                            }}>
                            Select Counrty
                          </p>
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        User Type
                        <Asterisk />
                      </Label>
                      <Select
                        value={optionSelectDropdown}
                        onChange={handleOptionSelectChange}
                        options={optionSelect}
                        classNamePrefix="select2-selection"
                        noOptionsMessage={() => "no data found.."}
                        maxMenuHeight={200}
                        isClearable
                      />
                      {optionError && (
                        <span>
                          <p
                            style={{
                              fontSize: "11.5px",
                              color: "red",
                            }}>
                            Select User Type
                          </p>
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Form>
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.privacy_policy || ""}
                            onReady={(editor) => { }}
                            onChange={handleEditorChange}
                          />
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div style={{ display: "inline-flex", gap: "14px" }}>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={formData.is_active}
                          id="invalid_Check"
                          name="is_global"
                          disabled={!!selectedTenant}
                          checked={formData.is_global}
                          onChange={handleTextChange}
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="invalid_Check">
                          Is Global
                        </label>
                      </div>

                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={formData.is_active}
                          id="invalidCheck"
                          name="is_active"
                          checked={formData.is_active}
                          onChange={handleTextChange}
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="invalidCheck">
                          Is Active
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  type="submit"
                  className="waves-effect waves-light me-1">
                  {id ? "Update" : "Submit"}
                </Button>
                <Button
                  color="primary"
                  className="waves-effect waves-light me-1"
                  onClick={() => navigate("/privacy-policy-list")}>
                  Back
                </Button>
              </>
            </AvForm>
            {loadingData && (
              <div style={loaderOverlay}>
                <LoaderCreatePage />
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyCreate;
