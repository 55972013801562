import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fetchData, image_url, academics } from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import { formContentContainer, loaderOverlay } from "../../../Style/loaderOverly";

const typeList = [
  {
    options: [
      { label: "Audio", value: 1 },
      { label: "Video", value: 2 },
      { label: "Document", value: 3 },
    ],
  },
];

const DefaultImagesCreate = ({ router }) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedType, setSelectedType] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [fetchedData, setFetchedData] = useState(null);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    inputData: {
      type: null,
      image: "",
      is_deleted: 0,
      is_active: 1,
    },

    uploadImage: {
      field_name: "image",
      folderPath: "",
      imagePath: "",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      const getSelectedType = typeList[0].options.find(
        (option) => option.value == data.type
      );
      if (getSelectedType) {
        setSelectedType(getSelectedType);
      }

      if (data.image) {
        setImageSrc(image_url + data.image);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "default_images",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSelectTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
    setTypeError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        type: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  //upload image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/jpeg", "image/png"];
    if (file) {
      if (!validTypes.includes(file.type)) {
        toast.error("select only a valid image file (JPG or PNG).");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedType ? setTypeError(true) : setTypeError(false);

    if (!selectedType) {
      return toast.warning("All Fields are required");
    }

    try {
      setLoadingData(true);

      var payload = {
        modelName: "default_images",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData.code == 1) {
        toast.success("Default Images saved successfully!");
        navigate("/default-images-list");
      } else {
        toast.error("Error While saving Default Images!");
        navigate("/default-images-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/default-images-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE DEFAULT IMAGE"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Type
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedType}
                                onChange={handleSelectTypeChange}
                                options={typeList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {typeError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Type
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Image</Label>
                              <input
                                name="group_image"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                onChange={handleImageChange}
                              />
                            </div>
                            {imageSrc && (
                              <Col md="6" className="mt-1">
                                <img
                                  src={imageSrc}
                                  alt={"image"}
                                  width="120px"
                                  height="100px"
                                  style={{
                                    border: ".5px solid gray",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DefaultImagesCreate;
