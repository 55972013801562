import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { dropdownData, fetchData } from "../../../services/fetchData";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import { formContentContainer, loaderOverlay } from "../../../Style/loaderOverly";

const OfflineDownloadStoragePeriodCreate = (props) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [featureList, setFeatureList] = useState([]);
  const [tenantError, setTenantError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [FeatureError, setFeatureError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    tenant_id: "",
    service_id: "",
    feature_id: "",
    action_count: "",
    point: "",
    is_deleted: 0,
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      let payload = {
        modelName: "features_gamification_rules",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          getTenantDropdownData(responseData.data[0].tenant_id);
          getServiceDropdownData(
            responseData.data[0].service_id,
            responseData.data[0].tenant_id
          );
          getFeatureDropdownData(
            responseData.data[0].feature_id,
            responseData.data[0].service_id
          );
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };
  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getServiceDropdownData = async (id, tenant_id) => {
    try {
      let payload = {
        modelName: "tenant_service_mappings",
        whereCondition: {
          tenant_id: tenant_id,
        },
        relations: [
          {
            module: "service_masters",
            moduleas: "service_masters",
          },
        ],
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "service_name",
          "join",
          "service_masters"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedService(element);
            }
          });
        }
        setServiceList(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getFeatureDropdownData = async (id, service_id) => {
    try {
      let payload = {
        modelName: "features_masters",
        whereCondition: { service_id: service_id, is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "feature_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedFeature(element);
            }
          });
        }

        setFeatureList(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    setTenantError(!selectedTenant);
    getServiceDropdownData("", selectedTenant ?selectedTenant.value:"");
    setFormData((prevState) => ({
      ...prevState,
      tenant_id: selectedTenant ? selectedTenant.value : "",
    }));
  };

  const handleSelectServiceChange = (selectedService) => {
    setSelectedService(selectedService);
    setServiceError(!selectedService);
    getFeatureDropdownData("",selectedService ? selectedService.value:"");
    setFormData((prevState) => ({
      ...prevState,
      service_id: selectedService ? selectedService.value : "",
    }));
  };

  const handleSelectFeatureChange = (selectedFeature) => {
    setSelectedFeature(selectedFeature);
    setFeatureError(!selectedFeature);
    setFormData((prevState) => ({
      ...prevState,
      feature_id: selectedFeature ? selectedFeature.value : "",
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setTenantError(!selectedTenant ? true : false);
    setServiceError(!selectedService ? true : false);
    setFeatureError(!selectedFeature ? true : false);

    if (
      !selectedTenant ||
      !selectedService ||
      !selectedFeature ||
      !formData.point ||
      !formData.action_count
    ) {
      return toast.warning("All fields are required");
    }

    try {
      setLoadingData(true);

      var payload = {
        modelName: "features_gamification_rules",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Features Gamification Rules saved successfully!");
        navigate("/feature-gamification-rules-list");
      } else {
        toast.error("Error While saving Features Gamification Rules!");
        navigate("/feature-gamification-rules-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE FEATURE GAMIFICATION RULES"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation"
                      id="tooltipForm">
                      <>
                        {" "}
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tenant
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleSelectTenantChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Action Count
                                <Asterisk />
                              </Label>
                              <AvField
                                name="action_count"
                                placeholder="Action Count"
                                type="number"
                                errorMessage="Enter Action Count"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "/^[0-9]+$/",
                                    errorMessage: "Only Numbers",
                                  },
                                }}
                                id="validationCustom01"
                                value={formData.action_count}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Service <Asterisk />
                              </Label>
                              <Select
                                value={selectedService}
                                onChange={handleSelectServiceChange}
                                options={serviceList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {serviceError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Service
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Points
                                <Asterisk />
                              </Label>
                              <AvField
                                name="point"
                                placeholder="Point"
                                type="number"
                                errorMessage="Enter Point"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "/^[0-9]+$/",
                                    errorMessage: "Only Numbers",
                                  },
                                }}
                                id="validationCustom01"
                                value={formData.point}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Feature <Asterisk />
                              </Label>
                              <Select
                                value={selectedFeature}
                                onChange={handleSelectFeatureChange}
                                options={featureList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {FeatureError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Feature
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className="waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light me-1"
                          onClick={() =>
                            navigate("/feature-gamification-rules-list")
                          }>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OfflineDownloadStoragePeriodCreate;
