import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

//Fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import AppRoute from "./routes/route";
import { fetchData } from "./services/fetchData";
import Loader from "./pages/Loader/Loader";

//Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASEURL,
// 	projectId: process.env.REACT_APP_PROJECTID,
// 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// 	appId: process.env.REACT_APP_APPID,
// 	measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.getLayout = this.getLayout.bind(this);
  }

  async componentDidMount() {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];
      
      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1
        },
      };
      if (authID.type == 1 && tenantId) { // admin
        payload.whereCondition.id = tenantId
      }
      if (authID.type == 2) { // tenant id
        payload.whereCondition.id = authID.tenant_id
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code === 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({ data: responseData.data });
        console.log("-", responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const { data } = this.state;
    const Layout = this.getLayout();


    return (
      <React.Fragment>
        {/* <Router>
					<Routes>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
					</Routes>
				</Router> */}

        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {
            // data.length > 0 ? (
            authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AppRoute>
                    <Layout>{route.component}</Layout>
                  </AppRoute>
                }
                key={idx}
                exact={true}
              />
            ))
          // ) : (
          //   <Route path="*" element={<Loader/>} />
          // )
          }
        </Routes>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
