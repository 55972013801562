import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  dropdownData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const colorList = [
  {
    options: [
      { label: "Blue", value: "Blue", color: "#0000FF" },
      { label: "Teal", value: "Teal", color: "#008080" },
      { label: "Amber", value: "Amber", color: "#FFBF00" },
      { label: "Green", value: "Green", color: "#008000" },
      { label: "Purple", value: "Purple", color: "#800080" },
      { label: "Orange", value: "Orange", color: "#FFA500" },
      { label: "Sapphire", value: "Sapphire", color: "#0F52BA" },
      { label: "Mustard Yellow", value: "Mustard Yellow", color: "#FFDB58" },
      { label: "Dusty Rose", value: "Dusty Rose", color: "#DCAE96" },
    ],
  },
];

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 18,
    width: 18,
  },
});

const ManageGroupIdCreate = ({ router }) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);

  const [selectedMegaHub, setSelectedMegaHub] = useState(null);
  const [megaHublist, setMegaHublist] = useState([]);
  const [megaHubError, setMegaHubError] = useState(false);

  const [selectedHub, setSelectedHub] = useState(null);
  const [hublist, setHublist] = useState([]);
  const [hubError, setHubError] = useState(false);

  const [selectedColor, setSelectedColor] = useState(null);
  const [colorError, setColorError] = useState(false);

  const [megaHubSelectedValue, setMegaHubSelectedValue] = useState(null);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      group_name: "",
      llc_group_id: "",
      mega_hub_id: "",
      hub_id: "",
      diamond_last_name: "",
      cms_name: "",
      group_abbreviation: "",
      group_image: "",
      group_color: "",
      is_active: 1,
    },

    uploadImage: {
      imagePath: "",
      folderPath: "",
      field_name: "group_image",
    },
  });

  const [groupImageSrc, setGroupImageSrc] = useState("");
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      const getSelectedColor = colorList[0].options.find(
        (option) => option.value === data.group_color
      );
      if (getSelectedColor) {
        setSelectedColor(getSelectedColor);
      }

      if (data.group_image) {
        setGroupImageSrc(image_url + data.group_image);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      getMegaHubDropdownData();
      // getHubDropdownData();
      let payload = {
        modelName: "group_id_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          getMegaHubDropdownData(data.mega_hub_id);
          getHubDropdownData(data.hub_id, data.mega_hub_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getMegaHubDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "mega_hub_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "hub_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedMegaHub(element);
            }
          });
        }
        setMegaHublist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getHubDropdownData = async (id, mega_hub_id) => {
    try {
      let payload = {
        modelName: "hub_masters",
        whereCondition: {
          is_active: 1,
          mega_hub_id: mega_hub_id,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "hub_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedHub(element);
            }
          });
        }
        setHublist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleSelectMegaHubChange = (selectedOption) => {
    setSelectedHub("");
    setSelectedMegaHub(selectedOption);
    setMegaHubError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        mega_hub_id: selectedOption ? selectedOption.value : "",
      },
    }));
    getHubDropdownData("", selectedOption?.value);
    setMegaHubSelectedValue(selectedOption?.value);
  };

  const handleSelectHubChange = (selectedOption) => {
    setSelectedHub(selectedOption);
    setHubError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        hub_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleSelectColorChange = (selectedOption) => {
    setSelectedColor(selectedOption);
    setColorError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        group_color: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  //upload  group image
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setGroupImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    !selectedTenant ? setTenantError(true) : setTenantError(false);
    !selectedMegaHub ? setMegaHubError(true) : setMegaHubError(false);
    !selectedHub ? setHubError(true) : setHubError(false);
    !selectedColor ? setColorError(true) : setColorError(false);

    if (
      !selectedTenant ||
      !selectedMegaHub ||
      !selectedHub ||
      !selectedColor ||
      !formData.inputData.group_name ||
      !formData.inputData.diamond_last_name ||
      !formData.inputData.group_abbreviation ||
      !formData.inputData.cms_name ||
      !formData.inputData.llc_group_id ||
      !formData.inputData.mega_hub_id ||
      !formData.inputData.hub_id
    ) {
      return toast.warning("All fields are required");
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "group_id_masters",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Group Id saved successfully!");
        navigate("/manage-group-id-list");
      } else {
        toast.error(responseData.message);
        // navigate("/manage-group-id-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/manage-group-id-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE MANAGE GROUP ID"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tanent
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleSelectTenantChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Mega Hub
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedMegaHub}
                                onChange={handleSelectMegaHubChange}
                                options={megaHublist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {megaHubError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Mega Hub
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Hub
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedHub}
                                onChange={handleSelectHubChange}
                                options={hublist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {hubError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Hub
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">Group Color</Label>
                              <Select
                                value={selectedColor}
                                onChange={handleSelectColorChange}
                                options={colorList}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                                styles={{
                                  singleValue: (styles, { data }) => ({
                                    ...styles,
                                    ...dot(data.color),
                                  }),
                                  option: (styles, { data }) => ({
                                    ...styles,
                                    ...dot(data.color),
                                  }),
                                }}
                              />
                              {colorError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Group Color
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Group Id Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="group_name"
                                placeholder="Group Id Name"
                                type="text"
                                errorMessage="Enter Group Id Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.group_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Group Id
                                <Asterisk />
                              </Label>
                              <AvField
                                name="llc_group_id"
                                placeholder="Group Id"
                                type="number"
                                min="1"
                                errorMessage="Enter Group Id"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[1-9]\\d*$",
                                    errorMessage:
                                      "Please enter a positive value greater than zero",
                                  },
                                }}
                                value={formData.inputData.llc_group_id}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Diamond Last Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="diamond_last_name"
                                placeholder=" Diamond Last Name"
                                type="text"
                                errorMessage="Enter  Diamond Last Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.diamond_last_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                CMS Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="cms_name"
                                placeholder=" CMS Name"
                                type="text"
                                errorMessage="Enter  CMS Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.cms_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Group Abbreviation
                                <Asterisk />
                              </Label>
                              <AvField
                                name="group_abbreviation"
                                placeholder="Group Abbreviation"
                                type="text"
                                errorMessage="Enter Group Abbreviation"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.group_abbreviation}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Group Image</Label>
                              <input
                                name="group_image"
                                type="file"
                                className="form-control"
                                onChange={handleProfileImageChange}
                              />
                            </div>
                            {groupImageSrc && (
                              <Col md="6" className="mt-1">
                                <img
                                  src={groupImageSrc}
                                  alt={"group image"}
                                  width="120px"
                                  height="100px"
                                  style={{
                                    border: ".5px solid gray",
                                    borderRadius: "5px",
                                  }}
                                />
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageGroupIdCreate;
