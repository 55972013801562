import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const TermsAndConditionsList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    let tenantId = localStorage.getItem("tenantId");
    let authID = localStorage.getItem("logedin-user");
    authID = JSON.parse(authID);
    authID = authID[0];
    try {
      const payload = {
        modelName: "terms_and_condition_masters",
        relations: [
          {
            module: "country_masters",
            moduleas: "country_masters",
          },
          {
            module: "tenant_masters",
            moduleas: "tenant_masters",
          },
        ],
        whereCondition: {
          is_deleted: 0,
        },
      };
      if (authID.type == 1 && tenantId) {
        payload.whereCondition.id = tenantId;
      }

      if (authID.type == 2) {
        payload.whereCondition.tenant_id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/terms-and-conditions-create");
  };

  const handleEditClick = (id) => {
    navigate(`/terms-and-conditions-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        id: id,
        modelName: "terms_and_condition_masters",
        inputData: {
          is_deleted: 1,
        },
      };

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData && responseData.code === 1) {
        toast.success("Terms and Conditions Deleted!");
        getData(); // Refres  h data after deletion
      } else {
        toast.error("Error while deleting the Terms and Conditions.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };
    showConfirmationToast(
      "Are you sure you want to delete this Terms and Conditions?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tenant",
        accessor: (row) =>
          row.tenant_masters && row.tenant_masters?.tenant_company_name
            ? row.tenant_masters.tenant_company_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "User Type",
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 1 ? "IBO" : "Guest"),
      },
      {
        Header: "Country Name",
        accessor: (row) =>
          row.country_masters && row.country_masters.country_name
            ? row.country_masters.country_name
            : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Terms and Condition List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Terms and Condition
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsAndConditionsList;
