import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  dropdownData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const PostSubTypeMastersCreate = ({ router }) => {
  const [breadcrumbItems] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();

  const [tenantlist, setTenantlist] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantError, setTenantError] = useState(false);

  const [postTypelist, setPostTypelist] = useState([]);
  const [selectedPostType, setSelectedPostType] = useState(null);
  const [postTypeError, setPostTypeError] = useState(false);

  const [iconImages, setIconImages] = useState();

  const [fetchedData, setFetchedData] = useState(null);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      post_type_id: "",
      sub_type_name: "",
      sub_type_icon: [{ type: 1, icon: "" }],
      is_deleted: 0,
      is_active: 1,
    },

    uploadImage: [
      {
        field_name: "sub_type_icon[0].icon",
        folderPath: "post_sub_type_masters",
        imagePath: "",
      },
    ],
  });

  useEffect(() => {
    getData(id);
  }, []);

  const mapTypesToNumbers = (data) => {
    const typeMap = {
      Small: 1,
    };

    if (data && data.length > 0) {
      return data.map((item) => ({
        type: typeMap[item.type] || item.type,
        icon: item.icon,
      }));
    }
  };

  useEffect(() => {
    if (fetchedData) {
      let mappedData;

      if (fetchedData.sub_type_icon) {
        mappedData = {
          ...fetchedData,
          sub_type_icon: mapTypesToNumbers(fetchedData.sub_type_icon),
        };
      } else {
        mappedData = {
          ...fetchedData,
          sub_type_icon: mapTypesToNumbers([{ type: 1, icon: "" }]),
        };
      }

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...mappedData,
        },
      }));

      if (mappedData.sub_type_icon[0].icon) {
        const newLandingPageImages = mappedData.sub_type_icon.map(
          (screen) => image_url + screen.icon
        );
        setIconImages(newLandingPageImages);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();
      getPostTypeDropdownData();
      let payload = {
        modelName: "post_sub_type_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(data.tenant_id);
          getPostTypeDropdownData(data.tenant_id, "getData", data.post_type_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPostTypeDropdownData = async (id, type, postTypeId) => {
    try {
      let payload = {
        modelName: "post_type_masters",
        relations: [
          {
            module: "tenant_masters",
            moduleas: "tenant_masters",
            required: true,
          },
        ],
        subWhereCondition: {
          // id: id,
          is_active: 1,
        },
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "type_name"
        );

        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == postTypeId) {
              setSelectedPostType(element);
            }
          });
        }
        setPostTypelist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedPostType(null);
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    getPostTypeDropdownData(selectedOption?.value);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleSelectPostTypeChange = (selectedOption) => {
    setSelectedPostType(selectedOption);
    setPostTypeError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        post_type_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== "image/svg+xml") {
        toast.error("select only an SVG image.");
        e.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const svgData = reader.result;
        const base64Data = window.btoa(svgData);
        const base64Svg = `data:image/svg;base64,${base64Data}`;

        setFormData((prevState) => {
          const newUploadImage = [...prevState.uploadImage];
          const categoryIconType =
            prevState.inputData.sub_type_icon[index].type;

          let uploadImageIndex;
          if (categoryIconType == 1 || categoryIconType == "Small") {
            uploadImageIndex = 0;
          } else {
            console.error("Invalid category icon type");
            return prevState;
          }

          if (
            uploadImageIndex !== undefined &&
            newUploadImage[uploadImageIndex]
          ) {
            newUploadImage[uploadImageIndex].imagePath = base64Svg;
            setIconImages(`data:image/svg+xml;base64,${base64Data}`);
            // setIconImages((prevImages) => {
            //   const newImages = [...prevImages];
            //   newImages[index] = `data:image/svg+xml;base64,${base64Data}`;
            //   return newImages;
            // });
          } else {
            console.error("Upload image index is out of bounds");
            return prevState;
          }

          return {
            ...prevState,
            uploadImage: newUploadImage,
          };
        });
      };
      reader.readAsText(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedTenant ? setTenantError(true) : setTenantError(false);
    !selectedPostType ? setPostTypeError(true) : setPostTypeError(false);

    if (
      !selectedTenant ||
      !selectedPostType ||
      !formData.inputData.sub_type_name
    ) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);

      const filteredUploadImage = formData.uploadImage.filter(
        (image) => image.imagePath !== ""
      );

      var payload = {
        modelName: "post_sub_type_masters",
        inputData: formData.inputData,
        uploadImage: filteredUploadImage,
      };

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Post Sub Type saved successfully!");
        navigate("/post-sub-type-list");
      } else {
        toast.error("Error while saving Post Sub Type!");
        navigate("/post-sub-type-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/post-sub-type-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE POST SUB TYPE"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row className="mb-2">
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tenant
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedTenant}
                                onChange={handleSelectTenantChange}
                                options={tenantlist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {tenantError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Tenant
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Post Sub Type
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedPostType}
                                onChange={handleSelectPostTypeChange}
                                options={postTypelist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {postTypeError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Post Sub Type
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Post Sub Type Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="sub_type_name"
                                placeholder="Post Sub Type Name"
                                type="text"
                                errorMessage="Enter Post Sub Type Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.sub_type_name}
                              />
                            </div>
                          </Col>
                        </Row>
                        <h6
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "160px",
                            backgroundColor: "grey",
                            color: "white",
                            paddingBlock: "5px",
                            paddingLeft: "5px",
                            marginBottom: "0px",
                            borderTopRightRadius: "15px",
                          }}>
                          Post Sub Type Icon
                        </h6>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                {formData.inputData &&
                                  formData.inputData.sub_type_icon && (
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Type</th>
                                        <th>Icon Upload (svg only)</th>
                                      </tr>
                                    </thead>
                                  )}
                                <tbody>
                                  {formData.inputData &&
                                    formData.inputData.sub_type_icon.map(
                                      (row, index) => (
                                        <tr
                                          key={row.key}
                                          style={{
                                            border: ".5px solid #bfbfbf",
                                          }}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <AvField
                                              name={`type-${index}`}
                                              placeholder="type"
                                              type="text"
                                              errorMessage="Enter type"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                row.type == 1 ||
                                                row.type == "Small"
                                                  ? "Small"
                                                  : ""
                                              }
                                              readOnly
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="file"
                                              className="form-control"
                                              key={index}
                                              onChange={(e) =>
                                                handleFileChange(e, index)
                                              }
                                              accept="image/svg+xml"
                                            />
                                            {iconImages && (
                                              <Col md="6" className="mt-1">
                                                <img
                                                  src={iconImages}
                                                  alt={"icon"}
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </Col>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PostSubTypeMastersCreate;
