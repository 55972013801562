import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, user } from "../../../services/fetchData";


const breadcrumbItems = [];

const CMSUserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");


  useEffect(() => {
    getData();
  }, [searchKeyword]);

  // get listing data
  const getData = async () => {
    try {
      const payload = {
        modelName: "cms_user_masters",
        search: {
          field_name: ["first_name", "last_name", "email_id"],
          searchKeyword: searchKeyword,
        },
        whereCondition: { is_active: 1 },
        pagination: {
          page: 1,
          pageSize: 1000,
        },
      };
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/cms-user-create"); 
  };

  const handleEditClick = (id) => {
    navigate(`/cms-user-create/${id}`); 
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "First Name",
        accessor: (row) =>
          row.first_name && row.first_name ? row.first_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Last Name",
        accessor: (row) => row.last_name && row.last_name ? row.last_name : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: (row) => row.email_id && row.email_id ? row.email_id : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: (row) => row.mobile_no && row.mobile_no ? row.mobile_no : "-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)} // Assuming `id` is the unique identifier
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

    const getSearchInputData = (inputValue) => {
      setSearchKeyword(inputValue);
    };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="CMS User List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create CMS User
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
      onSearchInputData={getSearchInputData}
              />
            </CardBody>
          </Card>
        </Container>
      </div>{" "}
    </React.Fragment>
  );
};

export default CMSUserList;
