import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import {
  dropdownData,
  fetchData,
  image_url,
  academics,
  user,
} from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dropStyle } from "../../../Style/dropStyle";

const optionEnableMode = [
  {
    options: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],
  },
];

const ContentModuleCreate = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  //toggling modals
  const [tagsIsHide, setTagsIsHide] = useState(false);
  const [postIsHide, setPostIsHide] = useState(true);
  const [categoriesIsHide, setCategoriesIsHide] = useState(true);
  const [usersIsHide, setUsersIsHide] = useState(true);

  //selected Group Drop
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState([]);
  const [previousSelectedGroup, setPreviousSelectedGroup] = useState([]);

  //selected Audiance drop
  const [selectedAudiance, setSelectedAudiance] = useState([]);
  const [selectedAudianceOption, setSelectedAudianceOption] = useState([]);
  const [previousSelectedAudiance, setPreviousSelectedAudiance] = useState([]);

  const [selectedEnableMode, setSelectedEnableMode] = useState(null);

  // TagList
  const [tagSelection, setTagSelection] = useState([]);
  const [tagFilteredSelection, setTagFilteredSelection] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const [tagSearchTerm, setTagSearchTerm] = useState("");
  const [tagSelectedItem, setTagSelectedItem] = useState([]);
  const [tagRemoveSelectedItem, setRemoveTagSelectedItem] = useState([]);

  //PostList
  const [postSelection, setPostSelection] = useState([]);
  const [postFilteredSelection, setPostFilteredSelection] = useState([]);
  const [postSelected, setPostSelected] = useState([]);
  const [postSearchTerm, setPostSearchTerm] = useState("");
  const [postSelectedItem, setPostSelectedItem] = useState([]);
  const [postRemoveSelectedItem, setRemovePostSelectedItem] = useState([]);

  //CategoryList
  const [categorySelection, setCategorySelection] = useState([]);
  const [categoryFilteredSelection, setCategoryFilteredSelection] = useState(
    []
  );
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);
  const [categorySelectedItem, setCategorySelectedItem] = useState([]);
  const [categoryRemoveSelectedItem, setRemoveCategorySelectedItem] = useState(
    []
  );

  //UserList

  const [userSelection, setUserSelection] = useState([]);
  const [userFilteredSelection, setUserFilteredSelection] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [userSelectedItem, setUserSelectedItem] = useState([]);
  const [userRemoveSelectedItem, setRemoveUserSelectedItem] = useState([]);

  // Dropdown Error
  const [groupError, setGroupError] = useState(false);
  const [audianceError, setAudianceError] = useState(false);

  // storing formData data
  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: 1,
      image: "",
      title: "",
      summary: "",
      keyword: [
        { type: "header", isChecked: false },
        { type: "title", isChecked: false },
        { type: "follows", isChecked: false },
      ],
      setting: 1,
      subscription: "",
      speaker_name: "",
      enable_notification: 0,
      post_title: "",
      post_subtitle: "",
      media: {
        title: "",
        type: "",
        url: "",
        content: "",
      },
      academic_channel_module_group_linkings: [],
      academic_channel_module_audience_linkings: [],
      academic_channel_module_tags: [],
      academic_channel_module_categories: [],
      academic_channel_users: [],
      academic_channel_module_associations: [],
      is_active: 1,
    },

    uploadImage: {
      imagePath: "",
      folderPath: "uploads",
      field_name: "image",
    },
  });

  const [fetchedData, setFetchedData] = useState(null);
  const [src, setSrc] = useState("");

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    getTagListData();
    getPostListData();
    geUserListtData();
    getCategoryListData();
  }, []);
  useEffect(() => {
    getSelectGroupDropData();
    getSelectAudianceDropData();
  }, []);

  useEffect(() => {
    setTagFilteredSelection(tagSelection);
    setPostFilteredSelection(postSelection);
    setUserFilteredSelection(userSelection);
    setCategoryFilteredSelection(categorySelection);
  }, [tagSelection, postSelection, userSelection, categorySelection]);

  useEffect(() => {
    if (fetchedData) {
      const defaultKeywords = [
        { type: "header", isChecked: false },
        { type: "title", isChecked: false },
        { type: "follows", isChecked: false },
      ];

      let mappedData = {
        ...fetchedData,
        keyword:
          Array.isArray(fetchedData.keyword) && fetchedData.keyword.length > 0
            ? fetchedData.keyword
            : defaultKeywords,
      };

      let data = mappedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.image) {
        setSrc(image_url + `${data.image}`);
      }

      const flattenedGroupOptions = selectedGroupOption.flatMap(
        (option) => option.options
      );
      const initialGroups = data.academic_channel_module_group_linkings.map(
        (group) => {
          let groupOption = flattenedGroupOptions.find(
            (option) => option.value == group.group_id
          );
          return {
            id: group.id,
            channel_id: group.channel_id,
            value: group.group_id,
            label: groupOption ? groupOption.label : group.group_id,
            is_active: 1,
          };
        }
      );

      setSelectedGroup(initialGroups);
      setPreviousSelectedGroup(initialGroups);

      const flattenedAudienceOptions = selectedAudianceOption.flatMap(
        (option) => option.options
      );
      const initialAudiences =
        data.academic_channel_module_audience_linkings.map((audience) => {
          let audienceOption = flattenedAudienceOptions.find(
            (option) => option.value == audience.audience_id
          );

          return {
            id: audience.id,
            channel_id: audience.channel_id,
            value: audience.audience_id,
            label: audienceOption ? audienceOption.label : audience.audience_id,
            is_active: 1,
          };
        });
      setSelectedAudiance(initialAudiences);
      setPreviousSelectedAudiance(initialAudiences);

      let accessTagId = data.academic_channel_module_tags.map(
        (item) => item.tag_id
      );
      let newfilterTagData = tagFilteredSelection.filter((item) =>
        accessTagId.includes(item.id)
      );
      setTagSelected(
        newfilterTagData.map((tag) => ({
          id: tag.id,
          tag_name: tag.tag_name,
          is_active: tag.is_active,
        }))
      );

      let accessCategoryId = data.academic_channel_module_categories.map(
        (item) => item.category_id
      );
      let newfilterCategoryData = categoryFilteredSelection.filter((item) =>
        accessCategoryId.includes(item.id)
      );
      setCategorySelected(
        newfilterCategoryData.map((category) => ({
          category_name: category.category_name,
          id: category.id,
          is_active: category.is_active,
        }))
      );

      let accessUserId = data.academic_channel_users.map(
        (item) => item.user_id
      );
      let newfilterUserData = userFilteredSelection.filter((item) =>
        accessUserId.includes(item.id)
      );
      setUserSelected(
        newfilterUserData.map((user) => ({
          first_name: user.first_name,
          id: user.id,
          is_active: user.is_active,
        }))
      );

      let accessPostId = data.academic_channel_module_associations.map(
        (item) => item.post_id
      );

      let newfilterPostData = postFilteredSelection.filter((item) =>
        accessPostId.includes(item.id)
      );

      setPostSelected(
        newfilterPostData.map((post) => ({
          id: post.id,
          title: post.title,
          is_active: post.is_active,
        }))
      );
    }
  }, [
    tagFilteredSelection,
    postFilteredSelection,
    userFilteredSelection,
    categoryFilteredSelection,
    fetchedData,
    selectedGroupOption,
    selectedAudianceOption,
  ]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "academic_channel_modules",
        relations: [
          {
            module: "academic_channel_module_group_linkings",
            moduleas: "academic_channel_module_group_linkings",
            subModule: "temp_group_id_masters",
            subModuleas: "temp_group_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_module_audience_linkings",
            moduleas: "academic_channel_module_audience_linkings",
            subModule: "temp_audience_id_masters",
            subModuleas: "temp_audience_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_module_tags",
            moduleas: "academic_channel_module_tags",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_module_categories",
            moduleas: "academic_channel_module_categories",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_users",
            moduleas: "academic_channel_users",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_module_associations",
            moduleas: "academic_channel_module_associations",
            subModule: "academic_channel_posts",
            subModuleas: "academic_channel_posts",
            isSubWhereConditionRequired: true,
          },
        ],

        subWhereCondition: {
          is_active: 1,
        },

        whereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id, ...payload.whereCondition };
      }
      let responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );

      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get ( tag/post/category/user ) list data
  const getTagListData = async () => {
    try {
      const payload = {
        modelName: "tag_masters",
        whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setTagSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPostListData = async () => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);

      let payload = {
        modelName: "academic_channel_posts",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setPostSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCategoryListData = async () => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        modelName: "category_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setCategorySelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const geUserListtData = async () => {
    try {
      const payload = {
        modelName: "cms_user_masters",
        whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload, user);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setUserSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Group drop data
  const getSelectGroupDropData = async () => {
    try {
      let payload = {
        modelName: "temp_group_id_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_group_id"
        );

        console.log("geting select group drop data => ", dropdownResponseData);

        setSelectedGroupOption(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Audiance drop data
  const getSelectAudianceDropData = async () => {
    try {
      let payload = {
        modelName: "temp_audience_id_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData(
        "getMasterList",
        payload,
        academics
      );

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_audience_id"
        );

        console.log(
          "geting select audience drop data => ",
          dropdownResponseData
        );
        setSelectedAudianceOption(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //  onchange Group / Audiance

  const handleSelectGroupChange = (selectedGroupOption) => {
    const currentGroupIds = selectedGroup.map((group) => group.value);
    const newGroupIds = selectedGroupOption.map((group) => group.value);

    const removedGroups = previousSelectedGroup
      .filter((group) => !newGroupIds.includes(group.value))
      .map((group) => ({ ...group, is_active: 0 }));
    const addedGroups = selectedGroupOption
      .filter((group) => !currentGroupIds.includes(group.value))
      .map((group) => ({
        value: group.value,
        label: group.label,
        is_active: 1,
      }));

    const updatedGroups = [
      ...previousSelectedGroup.filter(
        (group) => newGroupIds.includes(group.value) && group.is_active === 1
      ),
      ...addedGroups,
      ...removedGroups,
    ];


    setSelectedGroup(selectedGroupOption);
    setPreviousSelectedGroup(updatedGroups);

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_module_group_linkings: updatedGroups.map((group) => ({
          id: group.id,
          channel_id: group.channel_id,
          group_id: group.value,
          is_active: group.is_active,
        })),
      },
    }));
  };

  const handleSelectAudienceChange = (selectedAudianceOption) => {
    const currentAudienceIds = selectedAudiance.map(
      (audience) => audience.value
    );
    const newAudienceIds = selectedAudianceOption.map(
      (audience) => audience.value
    );

    const removedAudiences = previousSelectedAudiance
      .filter((audience) => !newAudienceIds.includes(audience.value))
      .map((audience) => ({ ...audience, is_active: 0 }));
    const addedAudiences = selectedAudianceOption
      .filter((audience) => !currentAudienceIds.includes(audience.value))
      .map((audience) => ({
        value: audience.value,
        label: audience.label,
        is_active: 1,
      }));

    const updatedAudiences = [
      ...previousSelectedAudiance.filter(
        (audience) =>
          newAudienceIds.includes(audience.value) && audience.is_active === 1
      ),
      ...addedAudiences,
      ...removedAudiences,
    ];

    setSelectedAudiance(selectedAudianceOption);
    setPreviousSelectedAudiance(updatedAudiences);

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_module_audience_linkings: updatedAudiences.map(
          (audience) => ({
            id: audience.id,
            channel_id: audience.channel_id,
            audience_id: audience.value,
            is_active: audience.is_active,
          })
        ),
      },
    }));
  };

  // For changing tooltip display prop
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  // Tags/Post/Category/Users modal open/close (toggeling)
  const handelTags = () => {
    setTagsIsHide(!tagsIsHide);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setUsersIsHide(true);
    if (!tagsIsHide) {
      scrollToSection("tagSection");
    }
  };

  const handelPost = () => {
    setPostIsHide(!postIsHide);
    setTagsIsHide(true);
    setCategoriesIsHide(true);
    setUsersIsHide(true);
    if (postIsHide) {
      scrollToSection("postSection");
    }
  };

  const handelCategories = () => {
    setCategoriesIsHide(!categoriesIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setUsersIsHide(true);
    if (categoriesIsHide) {
      scrollToSection("categoriesSection");
    }
  };

  const handelUsers = () => {
    setUsersIsHide(!usersIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    if (usersIsHide) {
      scrollToSection("usersSection");
    }
  };

  // Tags/Post/Category/Users - handleSearch
  const handleTagSearch = (e) => {
    const { value } = e.target;
    setTagSearchTerm(value);
    if (value === "") {
      setTagFilteredSelection(tagSelection);
    } else {
      setTagFilteredSelection(
        tagSelection.filter((item) =>
          item.tag_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handlePostSearch = (e) => {
    const { value } = e.target;
    setPostSearchTerm(value);
    if (value === "") {
      setPostFilteredSelection(postSelection);
    } else {
      setPostFilteredSelection(
        postSelection.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleCategorySearch = (e) => {
    const { value } = e.target;
    setCategorySearchTerm(value);
    if (value === "") {
      setCategoryFilteredSelection(categorySelection);
    } else {
      setCategoryFilteredSelection(
        categorySelection.filter((item) =>
          item.category_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleUserSearch = (e) => {
    const { value } = e.target;
    setUserSearchTerm(value);
    if (value === "") {
      setUserFilteredSelection(userSelection);
    } else {
      setUserFilteredSelection(
        userSelection.filter((item) =>
          item.first_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  // handel select :tag/post/category/user/

  const handleTagSelect = () => {
    if (tagSelectedItem) {
      const isTagAlreadySelected = tagSelected.some(
        (tag) => tag.id === tagSelectedItem.id
      );

      if (!isTagAlreadySelected) {
        setTagSelected([...tagSelected, tagSelectedItem]);
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_tags: [
              ...prevState.inputData.academic_channel_module_tags,
              {
                ...(id ? { id: null } : {}),
                tag_id: tagSelectedItem.id,
                is_active: 1,
              },
            ],
          },
        }));
        setTagSelectedItem(null);
      } else {
        toast.error("Tag already selected");
      }
    }
  };

  const handlePostSelect = () => {
    if (postSelectedItem) {
      const isPostAlreadySelected = postSelected.some(
        (tag) => tag.id === postSelectedItem.id
      );
      if (!isPostAlreadySelected) {
        setPostSelected([...postSelected, postSelectedItem]);
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_associations: [
              ...prevState.inputData.academic_channel_module_associations,
              {
                ...(id ? { id: null } : {}),
                post_id: postSelectedItem.id,
                is_active: 1,
              },
            ],
          },
        }));
        setPostSelectedItem(null);
      } else {
        toast.error("Post already selected");
      }
    }
  };

  const handleCategorySelect = () => {
    if (categorySelectedItem) {
      const isCategoryAlreadySelected = categorySelected.some(
        (tag) => tag.id === categorySelectedItem.id
      );
      if (!isCategoryAlreadySelected) {
        setCategorySelected([...categorySelected, categorySelectedItem]);
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_categories: [
              ...prevState.inputData.academic_channel_module_categories,
              {
                ...(id ? { id: null } : {}),
                category_id: categorySelectedItem.id,
                is_active: 1,
              },
            ],
          },
        }));
        setCategorySelectedItem(null);
      } else {
        toast.error("Category already selected");
      }
    }
  };

  const handleUserSelect = () => {
    if (userSelectedItem) {
      const isUserAlreadySelected = userSelected.some(
        (tag) => tag.id === userSelectedItem.id
      );
      if (!isUserAlreadySelected) {
        setUserSelected([...userSelected, userSelectedItem]);
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_users: [
              ...prevState.inputData.academic_channel_users,
              {
                ...(id ? { id: null } : {}),
                user_id: userSelectedItem.id,
                is_active: 1,
              },
            ],
          },
        }));
        setUserSelectedItem(null);
      } else {
        toast.error("User already selected");
      }
    }
  };

  //handle Rememove item data   Tags/Post/Category/Users

  // const handleTagRemove = () => {
  // if (tagRemoveSelectedItem) {
  //   setTagSelected((prevSelected) =>
  //     prevSelected.filter((tag) => tag.id !== tagRemoveSelectedItem.id)
  //   );
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     inputData: {
  //       ...prevState.inputData,
  //       academic_channel_module_tags: prevState.inputData.academic_channel_module_tags.filter(
  //         (tag) => tag.tag_id !== tagRemoveSelectedItem.id
  //       ),
  //     },
  //   }));
  //   setRemoveTagSelectedItem(null);
  // } else {
  //   toast.error("No tag selected to remove");
  // }
  // };

  const handleTagRemove = () => {
    if (tagRemoveSelectedItem) {
      if (id) {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_tags:
              prevState.inputData.academic_channel_module_tags.map((tag) =>
                tag.tag_id === tagRemoveSelectedItem.id
                  ? { ...tag, is_active: 0 }
                  : tag
              ),
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_tags:
              prevState.inputData.academic_channel_module_tags.filter(
                (tag) => tag.tag_id !== tagRemoveSelectedItem.id
              ),
          },
        }));
      }

      setTagSelected((prevSelected) =>
        prevSelected.filter((tag) => tag.id !== tagRemoveSelectedItem.id)
      );

      setRemoveTagSelectedItem(null);
    } else {
      toast.error("No tag selected to remove");
    }
  };

  const handlePostRemove = () => {
    if (postRemoveSelectedItem) {
      if (id) {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_associations:
              prevState.inputData.academic_channel_module_associations.map(
                (post) =>
                  post.post_id === postRemoveSelectedItem.id
                    ? { ...post, is_active: 0 }
                    : post
              ),
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_associations:
              prevState.inputData.academic_channel_module_associations.filter(
                (post) => post.post_id !== postRemoveSelectedItem.id
              ),
          },
        }));
      }

      setPostSelected((prevSelected) =>
        prevSelected.filter((post) => post.id !== postRemoveSelectedItem.id)
      );

      setRemovePostSelectedItem(null);
    } else {
      toast.error("No post selected to remove");
    }
  };

  const handleCategoryRemove = () => {
    if (categoryRemoveSelectedItem) {
      if (id) {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_categories:
              prevState.inputData.academic_channel_module_categories.map(
                (category) =>
                  category.category_id === categoryRemoveSelectedItem.id
                    ? { ...category, is_active: 0 }
                    : category
              ),
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_module_categories:
              prevState.inputData.academic_channel_module_categories.filter(
                (category) =>
                  category.category_id !== categoryRemoveSelectedItem.id
              ),
          },
        }));
      }

      setCategorySelected((prevSelected) =>
        prevSelected.filter(
          (category) => category.id !== categoryRemoveSelectedItem.id
        )
      );

      setRemoveCategorySelectedItem(null);
    } else {
      toast.error("No category selected to remove");
    }
  };

  const handleUserRemove = () => {
    if (userRemoveSelectedItem) {
      if (id) {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_users:
              prevState.inputData.academic_channel_users.map((user) =>
                user.user_id === userRemoveSelectedItem.id
                  ? { ...user, is_active: 0 }
                  : user
              ),
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          inputData: {
            ...prevState.inputData,
            academic_channel_users:
              prevState.inputData.academic_channel_users.filter(
                (user) => user.user_id !== userRemoveSelectedItem.id
              ),
          },
        }));
      }

      setUserSelected((prevSelected) =>
        prevSelected.filter((user) => user.id !== userRemoveSelectedItem.id)
      );

      setRemoveUserSelectedItem(null);
    } else {
      toast.error("No user selected to remove");
    }
  };

  //handle Item Click  Tags/Post/Category/Users
  const handleTagItemClick = (item) => {
    setTagSelectedItem(item);
  };

  const handlePostItemClick = (item) => {
    setPostSelectedItem(item);
  };

  const handleCategoryItemClick = (item) => {
    setCategorySelectedItem(item);
  };

  const handleUserItemClick = (item) => {
    setUserSelectedItem(item);
  };

  // handel Remove item data tag/post/category/user
  const handleRemoveTagItemClick = (item) => {
    setRemoveTagSelectedItem(item);
  };

  const handleRemovePostItemClick = (item) => {
    setRemovePostSelectedItem(item);
  };

  const handleRemoveCategoryItemClick = (item) => {
    setRemoveCategorySelectedItem(item);
  };

  const handleRemoveUserItemClick = (item) => {
    setRemoveUserSelectedItem(item);
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
      },
    }));
  };

  // handleCheckboxChange - header/title/follows
  const handleCheckboxChange = (type, checked) => {
    const updatedSetting = formData.inputData.keyword.map((item) =>
      item.type === type ? { ...item, isChecked: checked } : item
    );
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        keyword: updatedSetting,
      },
    }));
  };

  // uplaod Image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setSrc(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  //handle form Submit
  const handleSubmit = async (e) => {
  

    if (
      !formData.inputData.title ||
      !formData.inputData.speaker_name ||
      !formData.inputData.summary
    ) {
      return toast.warning("All fields are required");
    }
    try {
      var payload = {
        modelName: "academic_channel_modules",
        relation: [
          {
            modelName: "academic_channel_module_group_linkings",
          },
          {
            modelName: "academic_channel_module_audience_linkings",
          },
          {
            modelName: "academic_channel_module_tags",
          },
          {
            modelName: "academic_channel_module_categories",
          },
          {
            modelName: "academic_channel_users",
          },
          {
            modelName: "academic_channel_module_associations",
          },
        ],
        // inputData: formData.inputData,
        inputData: {
          ...formData.inputData,
          keyword: JSON.stringify(formData.inputData.keyword),
        },
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      console.log(
        "submitting  Module payload== >",
        payload
        // JSON.stringify(payload, null, 2)
      );

      const responseData = id
        ? await fetchData("updaterelationaldata", payload, academics)
        : await fetchData("createAndUpdateMaster", payload, academics);

      if (responseData.code == 1) {
        console.log("submiting Module if == >", responseData);
        toast.success("Module saved successfully!");
        navigate("/manage-module-list");
      } else {
        console.log("submiting Module else == >", responseData);
        toast.error("Error while saving Module !");
        navigate("/manage-module-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE MANNAGE MODULE"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row
                      className="mt-2"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <div className="m-3">
                        <h5
                          style={{
                            textAlign: "center",
                            fontFamily: "revert",
                            color: "#a6a6a6",
                            fontSize: "22px",
                          }}>
                          Channel Keywords
                        </h5>
                      </div>
                      <Row
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          display: "flex",
                          margin: 0,
                          padding: 0,
                        }}>
                        <Col
                          md="2"
                          style={{
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "center",

                            marginRight: "-22px",
                          }}>
                          <div
                            className="form-check mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "70%",
                              margin: 0,
                              padding: 0,
                              alignContent: "center",
                              alignItems: "center",
                              gap: 3,
                            }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="invalidCheck-1"
                              required=""
                              style={{
                                width: 18,
                                height: 18,
                                margin: 0,
                                padding: 0,
                              }}
                              Value={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "header"
                                )?.isChecked
                              }
                              checked={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "header"
                                )?.isChecked
                              }
                              onChange={(e) =>
                                handleCheckboxChange("header", e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invalidCheck-1"
                              style={{ margin: 0, padding: 0, fontSize: 16 }}>
                              Hide Header
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="2"
                          style={{
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            marginInline: "-22px",
                          }}>
                          <div
                            className="form-check mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "70%",
                              margin: 0,
                              padding: 0,
                              alignContent: "center",
                              alignItems: "center",
                              gap: 3,
                            }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="invalidCheck-2"
                              required=""
                              style={{
                                width: 18,
                                height: 18,
                                margin: 0,
                                padding: 0,
                              }}
                              value={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "title"
                                )?.isChecked
                              }
                              checked={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "title"
                                )?.isChecked
                              }
                              onChange={(e) =>
                                handleCheckboxChange("title", e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invalidCheck-2"
                              style={{ margin: 0, padding: 0, fontSize: 16 }}>
                              {" "}
                              Hide Title
                            </label>
                          </div>
                        </Col>

                        <Col
                          md="2"
                          style={{
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "-22px",
                          }}>
                          <div
                            className="form-check mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "70%",
                              margin: 0,
                              padding: 0,
                              alignContent: "center",
                              alignItems: "center",
                              gap: 3,
                            }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="invalidCheck-3"
                              required=""
                              style={{
                                width: 18,
                                height: 18,
                                margin: 0,
                                padding: 0,
                              }}
                              value={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "follows"
                                )?.isChecked
                              }
                              checked={
                                formData.inputData.keyword.find(
                                  (item) => item.type === "follows"
                                )?.isChecked
                              }
                              onChange={(e) =>
                                handleCheckboxChange(
                                  "follows",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invalidCheck-3"
                              style={{ margin: 0, padding: 0, fontSize: 16 }}>
                              Hide Follows
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Row>

                    <Row className="mt-5">
                      <div className="m-3 ">
                        <h5
                          style={{
                            textAlign: "center",
                            fontFamily: "revert",
                            color: "#a6a6a6",
                            fontSize: "22px",
                          }}>
                          Channel Settings
                        </h5>
                      </div>

                      <Row>
                        <div className="m-3" style={{}}>
                          <h4
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: 18,
                            }}>
                            Access
                          </h4>
                        </div>

                        <Row
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <Col md="3">
                            <div className="mb-3">
                              <Label className="form-label">subscription</Label>
                              <AvField
                                name="subscription"
                                placeholder="Subscription"
                                type="text"
                                errorMessage="Enter subscription"
                                className="form-control"
                                value={formData.inputData.subscription}
                                onChange={handleTextChange}
                              />
                            </div>
                          </Col>
                          <Col
                            style={{
                              textAlign: "center",
                            }}>
                            <div className="mt-3">
                              <i
                                style={{ fontSize: 18 }}
                                className="fas fa-trash"></i>
                            </div>
                          </Col>

                          <Col
                            style={{
                              textAlign: "center",
                            }}>
                            <div className="mt-3">
                              <i
                                style={{ fontSize: 18 }}
                                className="fas fa-edit"></i>
                            </div>
                          </Col>

                          <Col
                            style={{
                              textAlign: "center",
                            }}>
                            <div className="mt-3">
                              <i
                                style={{ fontSize: 18 }}
                                className="fas fa-eye"></i>
                            </div>
                          </Col>

                          <Col
                            style={{
                              textAlign: "center",
                            }}>
                            <div className="form-check mt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="enable_notification"
                                required=""
                                name="enable_notification"
                                value={formData.inputData.enable_notification}
                                checked={formData.inputData.enable_notification}
                                onChange={handleTextChange}
                                style={{ width: 18, height: 18 }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="enable_notification"
                                style={{ fontSize: 16, marginLeft: 10 }}>
                                Enable Notifications
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-5">
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Select Group</Label>
                              <Select
                                isMulti={true}
                                value={selectedGroup}
                                onChange={handleSelectGroupChange}
                                options={selectedGroupOption}
                                classNamePrefix="select1-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Select Audiance
                              </Label>
                              <Select
                                isMulti={true}
                                value={selectedAudiance}
                                onChange={handleSelectAudienceChange}
                                options={selectedAudianceOption}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                              \
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-1">
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Module title</Label>
                              <AvField
                                name="title"
                                placeholder="Module title"
                                type="text"
                                errorMessage="Enter module title Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.title}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Speaker name</Label>
                              <AvField
                                name="speaker_name"
                                placeholder="Speaker name"
                                type="text"
                                errorMessage="Enter speaker name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.speaker_name}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <Label
                              className="form-label"
                              htmlFor="mediaFileUpload">
                              Image
                            </Label>
                            <div className="input-group">
                              <input
                                type="file"
                                className="form-control"
                                id="mediaFileUpload"
                                onChange={handleFileChange}
                              />
                            </div>
                            {src && (
                              <Col md="6" className="mt-1">
                                <div className="mb-3">
                                  <div>
                                    <img
                                      src={src}
                                      alt="image"
                                      width="120px"
                                      height="100px"
                                      style={{
                                        border: ".5px solid gray",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Summary
                              </Label>
                              <AvField
                                name="summary"
                                placeholder="Summary "
                                type="textarea"
                                errorMessage="Enter Summary"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleTextChange}
                                value={formData.inputData.summary}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Row>

                      <Row className="mt-5">
                        <div className="m-3">
                          <h5
                            id="tagSection"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: 18,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: !tagsIsHide ? "black" : "gray",
                            }}
                            onClick={handelTags}>
                            Tags
                            <i
                              className={`mdi mdi-chevron-${
                                tagsIsHide ? "down" : "up"
                              } mt-1`}
                              style={{ fontSize: "22px" }}
                            />
                          </h5>
                        </div>
                        {!tagsIsHide && (
                          <Row
                            // id="tagSection"
                            style={{
                              marginLeft: "1px",
                              padding: "10px",
                              border: "1px solid gray",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 2px 1px gray",
                            }}>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col
                                md=""
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  className="form-check mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    alignContent: "center",
                                    alignItems: "center",
                                    gap: 3,
                                  }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="invalidCheck"
                                    required=""
                                    style={{
                                      width: 18,
                                      height: 18,
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      fontSize: 16,
                                    }}>
                                    Enable Visibility
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md={""}
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: "16px" }}>
                                    Enable Mode
                                  </Label>
                                  <Select
                                    value={selectedEnableMode}
                                    onChange={setSelectedEnableMode}
                                    options={optionEnableMode}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md="5"
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <AvField
                                    name="search"
                                    placeholder="Type of Search"
                                    type="text"
                                    errorMessage="Enter Type of Search"
                                    className="form-control m-0"
                                    // validate={{
                                    //   required: { value: true },
                                    // }}
                                    id="validationCustom01"
                                    onChange={handleTagSearch}
                                    value={tagSearchTerm}
                                  />

                                  <Button
                                    color="primary"
                                    type="search"
                                    className="waves-effect waves-light mb-3"
                                    onClick={() =>
                                      handleTagSearch({
                                        target: { value: tagSearchTerm },
                                      })
                                    }>
                                    Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label "
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selection: {tagFilteredSelection.length}
                                  </Label>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {tagFilteredSelection.map((item, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          cursor: "pointer",
                                          padding: "5px",
                                          // borderBottom: "1px solid #ccc",
                                          backgroundColor:
                                            tagSelectedItem?.id === item.id
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          handleTagItemClick(item)
                                        }>
                                        {item.tag_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Col>
                              <Col
                                md="1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-right"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleTagSelect}
                                    />
                                  </div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-left"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleTagRemove}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selected: {tagSelected.length}
                                  </Label>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {tagSelected.map((item, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          padding: "5px",
                                          cursor: "pointer",
                                          backgroundColor:
                                            tagRemoveSelectedItem?.id ===
                                            item.id
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          handleRemoveTagItemClick(item)
                                        }>
                                        {item.tag_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                      </Row>

                      <Row className="mt-5">
                        <div className="m-3">
                          <h5
                            id="postSection"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: 18,
                              color: !postIsHide ? "black" : "gray",
                            }}
                            onClick={handelPost}>
                            Posts{" "}
                            <i
                              className={`mdi mdi-chevron-${
                                postIsHide ? "down" : "up"
                              } mt-1`}
                              style={{ fontSize: "22px" }}
                            />
                          </h5>
                        </div>
                        {!postIsHide && (
                          <Row
                            // id="postSection"
                            style={{
                              marginLeft: "1px",
                              padding: "10px",
                              border: "1px solid gray",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 2px 1px gray",
                            }}>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col
                                md=""
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  className="form-check mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    alignContent: "center",
                                    alignItems: "center",
                                    gap: 3,
                                  }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="invalidCheck"
                                    required=""
                                    style={{
                                      width: 18,
                                      height: 18,
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      fontSize: 16,
                                    }}>
                                    Enable Visibility
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md={""}
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: "16px" }}>
                                    Enable Mode
                                  </Label>
                                  <Select
                                    value={selectedEnableMode}
                                    onChange={setSelectedEnableMode}
                                    options={optionEnableMode}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md="5"
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <AvField
                                    name="search"
                                    placeholder="Type of Search"
                                    type="text"
                                    errorMessage="Enter Type of Search"
                                    className="form-control m-0"
                                    // validate={{
                                    //   required: { value: true },
                                    // }}
                                    id="validationCustom01"
                                    onChange={handlePostSearch}
                                    value={postSearchTerm}
                                  />

                                  <Button
                                    color="primary"
                                    type="search"
                                    className="waves-effect waves-light mb-3"
                                    onClick={() =>
                                      handlePostSearch({
                                        target: { value: postSearchTerm },
                                      })
                                    }>
                                    Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label "
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selection: {postFilteredSelection.length}
                                  </Label>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {postFilteredSelection.map(
                                      (item, index) => (
                                        <li
                                          key={index}
                                          style={{
                                            cursor: "pointer",
                                            padding: "5px",
                                            // borderBottom: "1px solid #ccc",
                                            backgroundColor:
                                              postSelectedItem?.id === item.id
                                                ? "lightblue"
                                                : "",
                                          }}
                                          onClick={() =>
                                            handlePostItemClick(item)
                                          }>
                                          {item.title}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </Col>
                              <Col
                                md="1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-right"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handlePostSelect}
                                    />
                                  </div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-left"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handlePostRemove}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selected: {postSelected.length}
                                  </Label>
                                  <ul
                                    // id="postSection"
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {postSelected.map((item, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          padding: "5px",
                                          cursor: "pointer",
                                          backgroundColor:
                                            postRemoveSelectedItem?.id ===
                                            item.id
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          handleRemovePostItemClick(item)
                                        }>
                                        {item.title}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                      </Row>

                     
                      <Row className="mt-5">
                        <div className="m-3">
                          <h5
                            id="usersSection"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: 18,
                              color: !usersIsHide ? "black" : "gray",
                            }}
                            onClick={handelUsers}>
                            Users{" "}
                            <i
                              className={`mdi mdi-chevron-${
                                usersIsHide ? "down" : "up"
                              } mt-1`}
                              style={{ fontSize: "22px" }}
                            />
                          </h5>
                        </div>
                        {!usersIsHide && (
                          <Row
                            id="usersSection"
                            style={{
                              marginLeft: "1px",
                              padding: "10px",
                              border: "1px solid gray",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 2px 1px gray",
                            }}>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col
                                md=""
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  className="form-check mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    alignContent: "center",
                                    alignItems: "center",
                                    gap: 3,
                                  }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="invalidCheck"
                                    required=""
                                    style={{
                                      width: 18,
                                      height: 18,
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      fontSize: 16,
                                    }}>
                                    Enable Visibility
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md={""}
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <Label
                                    className="form-label"
                                    style={{ fontSize: "16px" }}>
                                    Enable Mode
                                  </Label>
                                  <Select
                                    value={selectedEnableMode}
                                    onChange={setSelectedEnableMode}
                                    options={optionEnableMode}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                                marginTop: "17px",
                              }}>
                              <Col
                                md="5"
                                style={{
                                  display: "flex",
                                  margin: 0,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    margin: 0,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "5px",
                                  }}>
                                  <AvField
                                    name="search"
                                    placeholder="Type of Search"
                                    type="text"
                                    errorMessage="Enter Type of Search"
                                    className="form-control m-0"
                                    // validate={{
                                    //   required: { value: true },
                                    // }}
                                    id="validationCustom01"
                                    onChange={handleUserSearch}
                                    value={userSearchTerm}
                                  />

                                  <Button
                                    color="primary"
                                    type="search"
                                    className="waves-effect waves-light mb-3"
                                    onClick={() =>
                                      handleUserSearch({
                                        target: { value: userSearchTerm },
                                      })
                                    }>
                                    Search
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: 0,
                                padding: 0,
                              }}>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label "
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selection: {userFilteredSelection.length}
                                  </Label>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {userFilteredSelection.map(
                                      (item, index) => (
                                        <li
                                          key={index}
                                          style={{
                                            cursor: "pointer",
                                            padding: "5px",
                                            // borderBottom: "1px solid #ccc",
                                            backgroundColor:
                                              userSelectedItem?.id === item.id
                                                ? "lightblue"
                                                : "",
                                          }}
                                          onClick={() =>
                                            handleUserItemClick(item)
                                          }>
                                          {item.first_name}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </Col>
                              <Col
                                md="1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-right"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleUserSelect}
                                    />
                                  </div>
                                  <div>
                                    <i
                                      className="fas fa-long-arrow-alt-left"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleUserRemove}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    Selected: {userSelected.length}
                                  </Label>
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      paddingLeft: 0,
                                      border: "1px solid black",
                                      height: "200px",
                                      overflow: "auto",
                                    }}>
                                    {userSelected.map((item, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          padding: "5px",
                                          cursor: "pointer",
                                          backgroundColor:
                                            userRemoveSelectedItem?.id ===
                                            item.id
                                              ? "lightblue"
                                              : "",
                                        }}
                                        onClick={() =>
                                          handleRemoveUserItemClick(item)
                                        }>
                                        {item.first_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                      </Row>
                    </Row>
                    <Row className="mt-3">
                      <Col lg="12">
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="invalidCheck"
                            name="is_active"
                            value={formData.inputData.is_active}
                            checked={formData.inputData.is_active}
                            onChange={handleTextChange}
                            required=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="invalidCheck">
                            Is Active
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      type="submit"
                      className=" waves-effect waves-light me-1">
                      {id ? "Update" : "Submit"}
                    </Button>

                    <Button
                      color="primary"
                      className=" waves-effect waves-light me-1"
                      onClick={() => navigate("/manage-module-list")}>
                      Back
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContentModuleCreate;
