import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";

const breadcrumbItems = [];

const TenantList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1
        },
      };

      if (authID.type == 1 && tenantId) { // admin
        payload.whereCondition.id = tenantId
      }
      if (authID.type == 2) { // tenant id
        payload.whereCondition.id = authID.tenant_id
      }
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tenant Company Name",
        accessor: (row) =>
        row.tenant_company_name &&  row.tenant_company_name ?  row.tenant_company_name :"-",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Contact Person Name",
        accessor: (row) => (row.contact_person_name && row.contact_person_name ? row.contact_person_name : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Contact Person Number",
        accessor: (row) => (row.contact_person_number && row.contact_person_number ? row.contact_person_number : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Contact Person Email",
        accessor: (row) => (row.contact_person_email && row.contact_person_email ? row.contact_person_email : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Website",
        accessor: (row) => (row.website && row.website ? row.website : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Address",
        accessor: (row) => (row.address && row.address ? row.address : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },

      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="primary"
            onClick={() => handleEditClick(row.original.id)} // Assuming `id` is the unique identifier
          >
            <i class="fas fa-edit"></i>
          </Button>
        ),
      },
    ],
    []
  );

  const handleClickAdd = () => {
    navigate("/tenant-create"); // Replace '/desired-path' with the path you want to navigate to
  };

  const handleEditClick = (id) => {
    navigate(`/tenant-create/${id}`); // Replace '/desired-path' with the path you want to navigate to
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Tenant List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Tenant
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TenantList;
