import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fetchData, social } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const ResourceCreate = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    inputData: {
      link: "",
      custome_link_name: "",
      is_deleted: 0,
      is_default: 0,
      is_active: 1,
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "resource_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload, social);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          setFormData((prevState) => ({
            ...prevState,
            inputData: {
              ...prevState.inputData,
              ...data,
            },
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inputData.custome_link_name || !formData.inputData.link) {
      return toast.warning("All fields are required");
    }

    try {
      setLoadingData(true);

      var payload = {
        modelName: "resource_masters",
        inputData: formData.inputData,
      };

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        social
      );
      if (responseData.code == 1) {
        toast.success("Resource saved successfully!");
        navigate("/resource-list");
      } else {
        toast.error("Error while saving Resource!");
        navigate("/resource-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/resource-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE RESOURCE"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Custome Link Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="custome_link_name"
                                placeholder="custome link name"
                                type="text"
                                errorMessage="Enter Custome Link Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.custome_link_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                link
                                <Asterisk />
                              </Label>
                              <AvField
                                name="link"
                                placeholder="link name"
                                type="text"
                                errorMessage="Enter Link Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "/^(https?://|www.)[^s/$.?#].[^s]*.com(/c/[a-f0-9-]+)?$/",
                                    errorMessage:
                                      "Enter a valid link start-'https:// or www' end-'.com'",
                                  },
                                }}
                                value={formData.inputData.link}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div
                              style={{ display: "inline-flex", gap: "14px" }}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.is_default}
                                  id="invalid_Check"
                                  name="is_default"
                                  checked={formData.inputData.is_default}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalid_Check">
                                  Is Default
                                </label>
                              </div>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.is_active}
                                  id="invalidCheck"
                                  name="is_active"
                                  checked={formData.inputData.is_active}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Is Active
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResourceCreate;
