import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const BadgeList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const payload = {
        modelName: "badge_masters",
        whereCondition: { is_deleted: 0 },
      };
      const responseData = await fetchData("getMasterList", payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/badges-create");
  };

  const handleEditClick = (id) => {
    navigate(`/badges-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        id: id,
        modelName: "badge_masters",
        inputData: {
          is_deleted: 1,
        },
      };

      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData && responseData.code === 1) {
        toast.success("Badge Deleted!");
        getData();
      } else {
        toast.error("Error while deleting the Badge.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Badge?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Name",
        accessor: (row) => (row.name && row.name ? row.name : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Points",
        accessor: (row) => (row.points && row.points ? row.points : "-"),
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Academic Badge List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Academic Badge
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BadgeList;
