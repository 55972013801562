const env = process.env.REACT_APP_ENV;


let apiUrl;
let imageUrl;

var baseUrlSocial = ""
var baseUrlUser = ""
var baseUrlCms = ""
var baseUrlAcademics = ""
var baseUrlChatfolder = ""

if (env == "testing") {
  console.log('testing');

  apiUrl = process.env.REACT_APP_GROWTH_API_TESTING;
  imageUrl = process.env.REACT_APP_IMAGE_URL;

  baseUrlSocial = apiUrl
  baseUrlUser = apiUrl
  baseUrlCms = apiUrl
  baseUrlAcademics = apiUrl
  baseUrlChatfolder = apiUrl
} else if (env === "staging") {
  console.log('staging');

  apiUrl = process.env.REACT_APP_GROWTH_API_STAGING;
  imageUrl = process.env.REACT_APP_IMAGE_URL;

  baseUrlSocial = apiUrl
  baseUrlUser = apiUrl
  baseUrlCms = apiUrl
  baseUrlAcademics = apiUrl
  baseUrlChatfolder = apiUrl
} else if (env == "production") {
  console.log('production');

  baseUrlSocial = process.env.REACT_APP_PROD_SOCIAL;
  baseUrlUser = process.env.REACT_APP_PROD_USER
  baseUrlCms = process.env.REACT_APP_PROD_CMS
  baseUrlAcademics = process.env.REACT_APP_PROD_ACADEMIC
  baseUrlChatfolder = process.env.REACT_APP_PROD_CHAT
}

console.log("image_url", imageUrl);
console.log("apiUrl", apiUrl);


export const api = apiUrl;
export const image_url = imageUrl;
export const academics = "academics/"
export const user = "user/"
export const chatfolder = "chatfolder/";
export const social = "social/";


const access_token = JSON.parse(localStorage.getItem("logedin-user")) ? JSON.parse(localStorage.getItem("logedin-user"))[0].access_token : null;
let azurADToken = access_token && access_token.azurADToken ? access_token.azurADToken : "";

// import { useNavigate } from "react-router-dom";
export const fetchData = async (url, payload, service) => {
  try {

    let api_url;
    if (service == academics) {
      api_url = baseUrlAcademics + service;
    } else if (service == user) {
      api_url = baseUrlUser + service;
    } else if (service == chatfolder) {
      api_url = baseUrlChatfolder + service;
    } else if (service == social) {
      api_url = baseUrlSocial + service;
    } else {
      api_url = baseUrlCms + "cms/";
    }

    const response = await fetch(api_url + url, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${azurADToken}`,
      }, // Set content type
      body: JSON.stringify(payload), // Stringify the payload
    });
    const responseData = await response.json();

    // Check if the token is invalid
    if (responseData && responseData.message === "Token Not Valide!") {
      console.log('Invalid token, logging out the user and redirecting to login');

      // Clear localStorage for the logged-in user
      if (localStorage.getItem("logedin-user")) {
        localStorage.removeItem("logedin-user");
        localStorage.removeItem("tenantId");
      }

      // Navigate using window.location
      window.location.href = "/"; // or "/" to redirect to the home page
    }

    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately (throw error, display error message, etc.)
    throw error; // Re-throw the error for further handling if needed
  }
};

export const dropdownData = async (
  responseData,
  fieldName,
  type,
  master_name
) => {
  try {
    var options = [];
    responseData.forEach((element) => {
      if (type == "user_access") {
        options.push({
          label: element[fieldName],
          value: element.id,
          type: element.type,
        });
      } else if (type == "join") {
        options.push({
          label: element[master_name][fieldName],
          value: element[master_name].id,
        });
      } else {
        options.push({ label: element[fieldName], value: element.id });
      }
    });
    const dropdownResponseData = [
      {
        options: options,
      },
    ];
    return dropdownResponseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors appropriately (throw error, display error message, etc.)
    throw error; // Re-throw the error for further handling if needed
  }
};

export const fileImport = async (url, formData, service) => {
  let api_url;
  if (service == academics) {
    api_url = baseUrlAcademics + service;
  } else if (service == user) {
    api_url = baseUrlUser + service;
  } else if (service == chatfolder) {
    api_url = baseUrlChatfolder + service;
  } else if (service == social) {
    api_url = baseUrlSocial + service;
  } else {
    api_url = baseUrlCms + "cms/";
  }
  const response = await fetch(api_url + url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${azurADToken}`,
    },
    body: formData,
  });

  const responseData = await response.json();
  return responseData.code;
};



// export const downloadBlobData = async (service, lastSegment) => {
//   const downloadurl = "downloadMediaUrl/" + lastSegment;
//   let api_url;
//   if (service == academics) {
//     api_url = baseUrlAcademics + service;
//   } else if (service == user) {
//     api_url = baseUrlUser + service;
//   } else if (service == chatfolder) {
//     api_url = baseUrlChatfolder + service;
//   } else if (service == social) {
//     api_url = baseUrlSocial + service;
//   } else {
//     api_url = baseUrlCms + "cms/";
//   }


//   fetch(api_url + downloadurl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       // Create a link to download the file
//       const downloadUrl = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = downloadUrl;
//       a.download = lastSegment; // Optional file name
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//     })
//     .catch((error) => console.error("Error downloading the file:", error));
// };

export const downloadBlobData = async (service, lastSegment) => {
  const downloadurl = "downloadMediaUrl/" + lastSegment;
  let api_url;

  // Set the appropriate base URL based on the service
  if (service == academics) {
    api_url = baseUrlAcademics + service;
  } else if (service == user) {
    api_url = baseUrlUser + service;
  } else if (service == chatfolder) {
    api_url = baseUrlChatfolder + service;
  } else if (service == social) {
    api_url = baseUrlSocial + service;
  } else {
    api_url = baseUrlCms + "cms/";
  }

  // Fetch the blob data and download the file
  const response = await fetch(api_url + downloadurl);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = lastSegment; // Optional file name
  document.body.appendChild(a);
  a.click();
  a.remove();
};
