import React from "react";
import { Navigate, useParams } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Utility
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

// Foundation (Master)
import EnvironmentList from "../pages/Masters/Environment/EnvironmentList";
import EnvironmentCreate from "../pages/Masters/Environment/EnvironmentCreate";
import RoleList from "../pages/Masters/Role/RoleList";
import RoleCreate from "../pages/Masters/Role/RoleCreate";
import TenantList from "../pages/Masters/Tenant/TenantList";
import TenantCreate from "../pages/Masters/Tenant/TenantCreate";
import ServiceList from "../pages/Masters/Service/ServiceList";
import ServiceCreate from "../pages/Masters/Service/ServiceCreate";
import FeatureActionList from "../pages/Masters/Feature-Action/FeatureActionList";
import FeatureActionCreate from "../pages/Masters/Feature-Action/FeatureActionCreate";
import ServiceMapping from "./../pages/Masters/Service-Mapping/ServiceMapping";
import FeatureActionMapping from "../pages/Masters/Feature-Action-Mapping/FeatureActionMapping";
import ShareCodeLimitsList from "../pages/Masters/Share-Code-Limits/ShareCodeLimitsList";
import ShareCodeLimitsCreate from "../pages/Masters/Share-Code-Limits/ShareCodeLimitsCreate";

// Thread
import ThreadPaticipantLimitList from "../pages/Thread/Thread-Paticipant-Limit/ThreadPaticipantLimitList";
import ThreadPaticipantLimitCreate from "../pages/Thread/Thread-Paticipant-Limit/ThreadPaticipantLimitCreate";
import ThreadCategoryList from "../pages/Thread/Thread-Category/ThreadCategoryList";
import ThreadCategoryCreate from "../pages/Thread/Thread-Category/ThreadCategoryCreate";

// User Management
import HubList from "../pages/User-Management/Hub/HubList";
import HubCreate from "../pages/User-Management/Hub/HubCreate";
import MegaHubList from "../pages/User-Management/Mega-Hub/MegaHubList";
import MegaHubCreate from "../pages/User-Management/Mega-Hub/MegaHubCreate";
import TenantUserList from "../pages/User-Management/Tenant-User/TenantUserList";
import TenantUserCreate from "../pages/User-Management/Tenant-User/TenantUserCreate";
import CMSUserCreate from "../pages/User-Management/CMS-User/CMSUserCreate";
import CMSUserList from "../pages/User-Management/CMS-User/CMSUserList";
import GuestUserList from "../pages/User-Management/Guest-User/GuestUserList";
import GuestUserCreate from "../pages/User-Management/Guest-User/GuestUserCreate";
import IBOUserList from "../pages/User-Management/IBO-User/IBOUserList";
import IBOUserCreate from "../pages/User-Management/IBO-User/IBOUserCreate";
import ManageGroupIdList from "../pages/User-Management/Manage-Group-Id/ManageGroupIdList";
import ManageGroupIdCreate from "../pages/User-Management/Manage-Group-Id/ManageGroupIdCreate";
import ManageAudienceIdList from "../pages/User-Management/Manage-Audience-Id/ManageAudienceIdList";
import ManageAudienceIdCreate from "../pages/User-Management/Manage-Audience-Id/ManageAudienceIdCreate";
import DesignationList from "../pages/User-Management/Designation/DesignationList";
import DesignationCreate from "../pages/User-Management/Designation/DesignationCreate";

// Application Settings
import CountryList from "../pages/Application-Settings/Country/CountryList";
import CountryCreate from "../pages/Application-Settings/Country/CountryCreate";
import MobileAppSettingsList from "../pages/Application-Settings/Mobile-App-Settings/MobileAppSettingsList";
import MobileAppSettingsCreate from "../pages/Application-Settings/Mobile-App-Settings/MobileAppSettingsCreate";
import IntegrationConfigurationList from "../pages/Application-Settings/Integration-Configuration/IntegrationConfigurationList";
import IntegrationConfigurationCreate from "../pages/Application-Settings/Integration-Configuration/IntegrationConfigurationCreate";
import TermsAndConditionsList from "../pages/Application-Settings/Terms-and-Conditions/TermsAndConditionsList";
import TermsAndConditionsCreate from "../pages/Application-Settings/Terms-and-Conditions/TermsAndConditionsCreate";
import PrivacyPolicyList from "../pages/Application-Settings/Privacy-Policy/PrivacyPolicyList";
import PrivacyPolicyCreate from "../pages/Application-Settings/Privacy-Policy/PrivacyPolicyCreate";
import AboutAppList from "../pages/Application-Settings/About-App/AboutAppList";
import AboutAppCreate from "../pages/Application-Settings/About-App/AboutAppCreate";

// Social
import PostContentLimitsList from "../pages/Social/Post-Content-Limits/PostContentLimitsList";
import PostContentLimitsCreate from "../pages/Social/Post-Content-Limits/PostContentLimitsCreate";
import PostTemplatesColourList from "../pages/Social/Post-Templates-Colour/PostTemplatesColourList";
import PostTemplatesColourCreate from "../pages/Social/Post-Templates-Colour/PostTemplatesColourCreate";
import PostTypeMastersList from "../pages/Social/Post-Type-Masters/PostTypeMastersList";
import PostTypeMastersCreate from "../pages/Social/Post-Type-Masters/PostTypeMastersCreate";
import PostSubTypeMastersList from "../pages/Social/Post-Sub-Type-Masters/PostSubTypeMastersList";
import PostSubTypeMastersCreate from "../pages/Social/Post-Sub-Type-Masters/PostSubTypeMastersCreate";
import PostHashtagMasterList from "../pages/Social/Post-Hashtag-Master/PostHashtagMasterList";
import PostHashtagMasterCreate from "../pages/Social/Post-Hashtag-Master/PostHashtagMasterCreate";
import ResourceList from "../pages/Social/Resource/ResourceList";
import ResourceCreate from "../pages/Social/Resource/ResourceCreate";
import PostReportingOptionsList from "../pages/Social/Post-Reporting-Options/PostReportingOptionsList";
import PostReportingOptionsCreate from "../pages/Social/Post-Reporting-Options/PostReportingOptionsCreate";

// Chat Folder
import TagMasterList from "../pages/Chat-Folder/Tag-Master/TagMasterList";
import TagMasterCreate from "../pages/Chat-Folder/Tag-Master/TagMasterCreate";

// Administrator
import OfflineDownloadStoragePeriodList from "../pages/Administrattor/OfflineDownloadStoragePeriod/OfflineDownloadStoragePeriodList";
import OfflineDownloadStoragePeriodCreate from "../pages/Administrattor/OfflineDownloadStoragePeriod/OfflineDownloadStoragePeriodCreate";
import UserAccess from "../pages/Administrattor/User-Access/UserAccess";


// Academics
import AcademicChannelsList from "../pages/Academics/Academic-Channels/AcademicChannelsList";
import AcademicChannelsCreate from "../pages/Academics/Academic-Channels/AcademicChannelsCreate";
import AcademicModuleList from "../pages/Academics/Academic-Module/AcademicModuleList";
import AcademicModuleCreate from "../pages/Academics/Academic-Module/AcademicModuleCreate";
import AcademicPostList from "../pages/Academics/Academic-Post/AcademicPostList";
import AcademicPostCreate from "../pages/Academics/Academic-Post/AcademicPostCreate";
import AcademicCategoryList from "../pages/Academics/Academic-Category/AcademicCategoryList";
import AcademicCategoryCreate from "../pages/Academics/Academic-Category/AcademicCategoryCreate";
import AcademicBannerList from "../pages/Academics/Academic-Banner/AcademicBannerList";
import AcademicBannerCreate from "../pages/Academics/Academic-Banner/AcademicBannerCreate";

// Contents
import ContentChannelList from "../pages/Contents/Content-Channel/ContentChannelList";
import ContentChannelCreate from "../pages/Contents/Content-Channel/ContentChannelCreate";
import ContentModuleList from "../pages/Contents/Content-Module/ContentModuleList";
import ContentModuleCreate from "../pages/Contents/Content-Module/ContentModuleCreate";
import ContentPostList from "../pages/Contents/Content-Post/ContentPostList";
import ContentPostCreate from "../pages/Contents/Content-Post/ContentPostCreate";
import ContentCategoryList from "../pages/Contents/Content-Category/ContentCategoryList";
import ContentCategoryCreate from "../pages/Contents/Content-Category/ContentCategoryCreate";
import ContentBannerList from "../pages/Contents/Content-Banner/ContentBannerList";
import ContentBannerCreate from "./../pages/Contents/Content-Banner/ContentBannerCreate";

// Academic And Content
import DefaultKeywordList from "../pages/Academic-and-Content/Default-Keyword/DefaultKeywordList";
import DefaultKeywordCreate from "../pages/Academic-and-Content/Default-Keyword/DefaultKeywordCreate";
import AcademicTagList from "../pages/Academic-and-Content/Academic-Tag/AcademicTagList";
import AcademicTagCreate from "../pages/Academic-and-Content/Academic-Tag/AcademicTagCreate";
import DefaultImagesCreate from "../pages/Academic-and-Content/Default-Images/DefaultImagesCreate";
import DefaultImagesList from "../pages/Academic-and-Content/Default-Images/DefaultImagesList";


// Gamification
import FeatureGamificationRulesList from "../pages/Gamification/Feature-Gamification-Rules/FeatureGamificationRulesList";
import FeatureGamificationRulesCreate from "../pages/Gamification/Feature-Gamification-Rules/FeatureGamificationRulesCreate";
import BadgeCreate from "../pages/Gamification/Badge/BadgeCreate";
import BadgeList from "../pages/Gamification/Badge/BadgeList";

// Broadcast
import GroupBroadCast from "../pages/BroadCast/Group-BroadCast/GroupBroadCast";
import GroupBroadCastList from "../pages/BroadCast/Group-BroadCast/GroupBroadCastList";

const authProtectedRoutes = [
  // >>>>>>>>>>  Dashboard  <<<<<<<<<<
  { path: "/dashboard", component: <Dashboard /> },

  //⭕>>>>>>>>>>  Masters  <<<<<<<<<<⭕

  // Environment
  { path: "/environment-list", component: <EnvironmentList /> },
  { path: "/environment-create", component: <EnvironmentCreate /> },
  { path: "/environment-create/:id", component: <EnvironmentCreate /> },

  // Role
  { path: "/role-list", component: <RoleList /> },
  { path: "/role-create", component: <RoleCreate /> },
  { path: "/role-create/:id", component: <RoleCreate /> },

  // Tenant
  { path: "/tenant-list", component: <TenantList /> },
  { path: "/tenant-create", component: <TenantCreate /> },
  { path: "/tenant-create/:id", component: <TenantCreate /> },

  // Service
  { path: "/service-list", component: <ServiceList /> },
  { path: "/service-create", component: <ServiceCreate /> },
  { path: "/service-create/:id", component: <ServiceCreate /> },

  // Feature
  { path: "/feature-list", component: <FeatureActionList /> },
  { path: "/feature-create", component: <FeatureActionCreate /> },
  { path: "/feature-create/:id", component: <FeatureActionCreate /> },

  //Share code limits
  { path: "/share-code-limits-list", component: <ShareCodeLimitsList /> },
  { path: "/share-code-limits-create", component: <ShareCodeLimitsCreate /> },
  {
    path: "/share-code-limits-create/:id",
    component: <ShareCodeLimitsCreate />,
  },

  // Service and Feature mapping
  { path: "/service-mapping-list", component: <ServiceMapping /> },
  { path: "/feature-mapping-list", component: <FeatureActionMapping /> },

  //⭕>>>>>>>>>>  Threadt  <<<<<<<<<<⭕

  // Thread Paticipant Limit
  {
    path: "/thread-paticipant-limit-list",
    component: <ThreadPaticipantLimitList />,
  },
  {
    path: "/thread-paticipant-limit-create",
    component: <ThreadPaticipantLimitCreate />,
  },
  {
    path: "/thread-paticipant-limit-create/:id",
    component: <ThreadPaticipantLimitCreate />,
  },

  // Thread Category
  { path: "/thread-category-list", component: <ThreadCategoryList /> },
  { path: "/thread-category-create", component: <ThreadCategoryCreate /> },
  { path: "/thread-category-create/:id", component: <ThreadCategoryCreate /> },

  //⭕>>>>>>>>>>  User Management  <<<<<<<<<<⭕

  // Hub
  { path: "/hub-list", component: <HubList /> },
  { path: "/hub-create", component: <HubCreate /> },
  { path: "/hub-create/:id", component: <HubCreate /> },

  // Mega Hub
  { path: "/mega-hub-list", component: <MegaHubList /> },
  { path: "/mega-hub-create", component: <MegaHubCreate /> },
  { path: "/mega-hub-create/:id", component: <MegaHubCreate /> },

  // Teant User
  { path: "/tenant-user-list", component: <TenantUserList /> },
  { path: "/tenant-user-create", component: <TenantUserCreate /> },
  { path: "/tenant-user-create/:id", component: <TenantUserCreate /> },

  // CMS User
  { path: "/cms-user-list", component: <CMSUserList /> },
  { path: "/cms-user-create", component: <CMSUserCreate /> },
  { path: "/cms-user-create/:id", component: <CMSUserCreate /> },

  // Guest User
  { path: "/guest-user-list", component: <GuestUserList /> },
  { path: "/guest-user-create", component: <GuestUserCreate /> },

  // IBO User
  { path: "/ibo-user-list", component: <IBOUserList /> },
  { path: "/ibo-user-create", component: <IBOUserCreate /> },

  // Manage Group Id
  { path: "/manage-group-id-list", component: <ManageGroupIdList /> },
  { path: "/manage-group-id-create", component: <ManageGroupIdCreate /> },
  { path: "/manage-group-id-create/:id", component: <ManageGroupIdCreate /> },

  // Manage Audience Id
  { path: "/manage-audience-id-list", component: <ManageAudienceIdList /> },
  {
    path: "/manage-audience-id-create",
    component: <ManageAudienceIdCreate />,
  },
  {
    path: "/manage-audience-id-create/:id",
    component: <ManageAudienceIdCreate />,
  },

  // Designation
  { path: "/designation-list", component: <DesignationList /> },
  { path: "/designation-create", component: <DesignationCreate /> },
  { path: "/designation-create/:id", component: <DesignationCreate /> },

  //⭕>>>>>>>>>>  Application Settings  <<<<<<<<<<⭕

  // Country
  { path: "/country-list", component: <CountryList /> },
  { path: "/country-create", component: <CountryCreate /> },
  { path: "/country-create/:id", component: <CountryCreate /> },

  // Mobile App Settings
  {
    path: "/mobile-app-settings-list",
    component: <MobileAppSettingsList />,
  },
  {
    path: "/mobile-app-settings-create",
    component: <MobileAppSettingsCreate />,
  },
  {
    path: "/mobile-app-settings-create/:id",
    component: <MobileAppSettingsCreate />,
  },

  // Integration Configuration
  {
    path: "/integration-configuration-list",
    component: <IntegrationConfigurationList />,
  },
  {
    path: "/integration-configuration-create",
    component: <IntegrationConfigurationCreate />,
  },
  {
    path: "/integration-configuration-create/:id",
    component: <IntegrationConfigurationCreate />,
  },

  // Terms and Conditions
  {
    path: "/terms-and-conditions-list",
    component: <TermsAndConditionsList />,
  },
  {
    path: "/terms-and-conditions-create",
    component: <TermsAndConditionsCreate />,
  },
  {
    path: "/terms-and-conditions-create/:id",
    component: <TermsAndConditionsCreate />,
  },

  // About App
  {
    path: "/about-app-list",
    component: <AboutAppList />,
  },
  {
    path: "/about-app-create",
    component: <AboutAppCreate />,
  },
  {
    path: "/about-app-create/:id",
    component: <AboutAppCreate />,
  },

  // Privacy Policy
  {
    path: "/privacy-policy-list",
    component: <PrivacyPolicyList />,
  },
  {
    path: "/privacy-policy-create",
    component: <PrivacyPolicyCreate />,
  },
  {
    path: "/privacy-policy-create/:id",
    component: <PrivacyPolicyCreate />,
  },

  // ⭕>>>>>>>>>> Social <<<<<<<<<<⭕

  // Post Content Limit
  {
    path: "/post-content-limit-list",
    component: <PostContentLimitsList />,
  },
  {
    path: "/post-content-limit-create",
    component: <PostContentLimitsCreate />,
  },
  {
    path: "/post-content-limit-create/:id",
    component: <PostContentLimitsCreate />,
  },

  // Post Color
  {
    path: "/post-color",
    component: <PostTemplatesColourList />,
  },
  {
    path: "/post-color-create",
    component: <PostTemplatesColourCreate />,
  },
  {
    path: "/post-color-create/:id",
    component: <PostTemplatesColourCreate />,
  },

  // Post Type Master
  {
    path: "/post-type-list",
    component: <PostTypeMastersList />,
  },
  {
    path: "/post-type-create",
    component: <PostTypeMastersCreate />,
  },
  {
    path: "/post-type-create/:id",
    component: <PostTypeMastersCreate />,
  },

  // Post Sub Type Master
  {
    path: "/post-sub-type-list",
    component: <PostSubTypeMastersList />,
  },
  {
    path: "/post-sub-type-create",
    component: <PostSubTypeMastersCreate />,
  },
  {
    path: "/post-sub-type-create/:id",
    component: <PostSubTypeMastersCreate />,
  },

  // Post Hashtag
  {
    path: "/post-hashtag-list",
    component: <PostHashtagMasterList />,
  },
  {
    path: "/post-hashtag-create",
    component: <PostHashtagMasterCreate />,
  },
  {
    path: "/post-hashtag-create/:id",
    component: <PostHashtagMasterCreate />,
  },

  // Resource
  {
    path: "/resource-list",
    component: <ResourceList />,
  },
  {
    path: "/resource-create",
    component: <ResourceCreate />,
  },
  {
    path: "/resource-create/:id",
    component: <ResourceCreate />,
  },

  //Post Reporting Options
  {
    path: "/report-list",
    component: <PostReportingOptionsList />,
  },
  {
    path: "/report-create",
    component: <PostReportingOptionsCreate />,
  },
  {
    path: "/report-create/:id",
    component: <PostReportingOptionsCreate />,
  },

  // ⭕>>>>>>>>>> Chat Folder <<<<<<<<<<⭕

  //Tag masters
  { path: "/tag-list", component: <TagMasterList /> },
  { path: "/tag-create", component: <TagMasterCreate /> },
  { path: "/tag-create/:id", component: <TagMasterCreate /> },

  // ⭕>>>>>>>>>> Administrator <<<<<<<<<<⭕

  //  Offline Download Storage Period
  {
    path: "/offline-download-storage-period-list",
    component: <OfflineDownloadStoragePeriodList />,
  },
  {
    path: "/offline-download-storage-period-create",
    component: <OfflineDownloadStoragePeriodCreate />,
  },
  {
    path: "/offline-download-storage-period-create/:id",
    component: <OfflineDownloadStoragePeriodCreate />,
  },
  // User Access
  {
    path: "/user-access-list",
    component: <UserAccess />,
  },

  //Default Images
  {
    path: "/default-images-list",
    component: <DefaultImagesList />,
  },
  {
    path: "/default-images-create",
    component: <DefaultImagesCreate />,
  },
  {
    path: "/default-images-create/:id",
    component: <DefaultImagesCreate />,
  },

  // ⭕>>>>>>>>>> Academics <<<<<<<<<<⭕

  // Academics Channels
  {
    path: "/academic-channels-list",
    component: <AcademicChannelsList />,
  },
  {
    path: "/academic-channels-create",
    component: <AcademicChannelsCreate />,
  },
  {
    path: "/academic-channels-create/:id",
    component: <AcademicChannelsCreate />,
  },

  // Academics Module
  {
    path: "/academic-module-list",
    component: <AcademicModuleList />,
  },
  {
    path: "/academic-module-create",
    component: <AcademicModuleCreate />,
  },
  {
    path: "/academic-module-create/:id",
    component: <AcademicModuleCreate />,
  },

  // Academic Post
  {
    path: "/academic-post-list",
    component: <AcademicPostList />,
  },
  {
    path: "/academic-post-create",
    component: <AcademicPostCreate />,
  },
  {
    path: "/academic-post-create/:id",
    component: <AcademicPostCreate />,
  },

  // Academic Category
  {
    path: "/academic-category-list",
    component: <AcademicCategoryList />,
  },
  {
    path: "/academic-category-create",
    component: <AcademicCategoryCreate />,
  },
  {
    path: "/academic-category-create/:id",
    component: <AcademicCategoryCreate />,
  },

  // Academic Banner
  {
    path: "/academic-banner-list",
    component: <AcademicBannerList />,
  },
  {
    path: "/academic-banner-create",
    component: <AcademicBannerCreate />,
  },
  {
    path: "/academic-banner-create/:id",
    component: <AcademicBannerCreate />,
  },

  // ⭕>>>>>>>>>> Contents <<<<<<<<<<⭕

  // Manage Content channel
  {
    path: "/content-channel-list",
    component: <ContentChannelList />,
  },
  {
    path: "content-channel-create",
    component: <ContentChannelCreate />,
  },
  {
    path: "content-channel-create/:id",
    component: <ContentChannelCreate />,
  },

  // Manage Content Module
  {
    path: "/manage-module-list",
    component: <ContentModuleList />,
  },
  {
    path: "/manage-module-create",
    component: <ContentModuleCreate />,
  },
  {
    path: "/manage-module-create/:id",
    component: <ContentModuleCreate />,
  },

  // Content Channel Post
  {
    path: "/content-post-list",
    component: <ContentPostList />,
  },
  {
    path: "/content-post-create",
    component: <ContentPostCreate />,
  },
  {
    path: "/content-post-create/:id",
    component: <ContentPostCreate />,
  },

  // Content Category
  {
    path: "/content-category-list",
    component: <ContentCategoryList />,
  },
  {
    path: "/content-category-create",
    component: <ContentCategoryCreate />,
  },
  {
    path: "/content-category-create/:id",
    component: <ContentCategoryCreate />,
  },

  // Content Banner
  {
    path: "/content-banner-list",
    component: <ContentBannerList />,
  },
  {
    path: "/content-banner-create",
    component: <ContentBannerCreate />,
  },
  {
    path: "/content-banner-create/:id",
    component: <ContentBannerCreate />,
  },

  // ⭕>>>>>>>>>> Academic And  Content <<<<<<<<<<⭕

  // Default Keyword
  {
    path: "/default-keyword-list",
    component: <DefaultKeywordList />,
  },
  {
    path: "/default-keyword-create",
    component: <DefaultKeywordCreate />,
  },
  {
    path: "/default-keyword-create/:id",
    component: <DefaultKeywordCreate />,
  },

  // Academic Tag
  {
    path: "/academic-tag-list",
    component: <AcademicTagList />,
  },
  {
    path: "/academic-tag-create",
    component: <AcademicTagCreate />,
  },
  {
    path: "/academic-tag-create/:id",
    component: <AcademicTagCreate />,
  },

  // ⭕>>>>>>>>>> Gamification <<<<<<<<<<⭕

  // Feature Gamification Rules
  {
    path: "/feature-gamification-rules-list",
    component: <FeatureGamificationRulesList />,
  },
  {
    path: "/feature-gamification-rules-create",
    component: <FeatureGamificationRulesCreate />,
  },
  {
    path: "/feature-gamification-rules-create/:id",
    component: <FeatureGamificationRulesCreate />,
  },
  // Academic Badge
  {
    path: "/badges-list",
    component: <BadgeList />,
  },
  {
    path: "/badges-create",
    component: <BadgeCreate />,
  },
  {
    path: "/badges-create/:id",
    component: <BadgeCreate />,
  },

  // ⭕>>>>>>>>>> BroadCast <<<<<<<<<<⭕

  // Group BroadCast
  {
    path: "/group-broadcast",
    component: <GroupBroadCastList />,
  },
  {
    path: "/group-broadcast-create",
    component: <GroupBroadCast />,
  },
  {
    path: "/group-broadcast-create/:id",
    component: <DefaultKeywordCreate />,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/lock-screen", component: <AuthLockScreen /> },

  // Authentication Inner
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <ForgetPwd1 /> },

  { path: "/maintenance", component: <Maintenance /> },
  { path: "/comingsoon", component: <CommingSoon /> },
  { path: "/404", component: <Error404 /> },
  { path: "/500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
