import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchData } from "../../../services/fetchData";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const IntegrationConfigurationCreate = () => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    framework_name: "",
    api_key: "",
    api_secrete: "",
    api_url: "",
    android_key: "",
    ios_key: "",
    client_id: "",
    domain: "",
    redirection_url_ios: "",
    redirection_url_android: "",
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "integration_configurations",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const validateForm = () => {
    const { api_url } = formData;
    const apiUrlRegex = /^(https?:\/\/(www\.)?|www\.)[^\s/$.?#].[^\s]*$/;

    if (!apiUrlRegex.test(api_url)) {
      toast.error("Enter a valid API url");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.framework_name) {
      return toast.warning("All fields are required");
    }

    // if (!validateForm()) {
    //   return;
    // }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "integration_configurations",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Integration Configurations saved successfully!");
        navigate("/integration-configuration-list");
      } else {
        toast.error("Error while saving Integration Configurations!");
        navigate("/integration-configuration-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE Integration Configuration"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      id="tooltipForm"
                      onSubmit={handleSubmit}>
                      <>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Framework Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="framework_name"
                                placeholder="Framework Name"
                                type="text"
                                errorMessage="Enter Framework Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.framework_name}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                API Key
                              </Label>
                              <AvField
                                name="api_key"
                                placeholder="API Key"
                                type="text"
                                errorMessage="Enter API Key"
                                className="form-control"
                                // validate={{ required: { value: true } }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.api_key}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                API Secrete
                              </Label>
                              <AvField
                                name="api_secrete"
                                placeholder="API Secrete"
                                type="text"
                                errorMessage="Enter API Secrete"
                                className="form-control"
                                // validate={{ required: { value: true } }}
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.api_secrete}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                API URL
                              </Label>
                              <AvField
                                name="api_url"
                                placeholder="API URL"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.api_url}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Domain
                              </Label>
                              <AvField
                                name="domain"
                                placeholder="Domain"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.domain}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Redirection URL IOS
                              </Label>
                              <AvField
                                name="redirection_url_ios"
                                placeholder="Redirection URL IOS"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.redirection_url_ios}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Redirection URL Android
                              </Label>
                              <AvField
                                name="redirection_url_android"
                                placeholder="Redirection URL Android"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.redirection_url_android}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Android Key
                              </Label>
                              <AvField
                                name="android_key"
                                placeholder="Android Key"
                                type="textarea"
                                rows="3"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.android_key}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                IOS Key
                              </Label>
                              <AvField
                                name="ios_key"
                                placeholder="IOS Key"
                                type="textarea"
                                rows="3"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.ios_key}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Client Id
                              </Label>
                              <AvField
                                name="client_id"
                                placeholder="Client Id"
                                type="textarea"
                                rows="3"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleTextChange}
                                value={formData.client_id}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className="waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className="waves-effect waves-light me-1"
                          onClick={() =>
                            navigate("/integration-configuration-list")
                          }>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IntegrationConfigurationCreate;
