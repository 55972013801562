import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const FeatureActionMapping = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [breadcrumbItems] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [servicelist, setServicelist] = useState([]);
  const [onGo, setOnGo] = useState(false);
  const [data, setData] = useState([]);
  const [existingMappings, setExistingMappings] = useState([]);
  const [tenantId, setTenantId] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [checkedServices, setCheckedServices] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTanentDropdownData();
  }, []);

  useEffect(() => {
    getOnGoData();
  }, [serviceId]);

  const getOnGoData = async () => {
    try {
      setLoading(true);
      if (!tenantId || !serviceId) {
        return false;
      }
      // Fetch the active services
      let servicePayload = {
        modelName: "features_masters",
        whereCondition: { service_id: serviceId, is_active: 1 },
      };
      const serviceResponse = await fetchData("getMasterList", servicePayload);
  

      if (serviceResponse.data) {
        setData(serviceResponse.data);

        // Fetch the tenant's service mappings
        let mappingPayload = {
          modelName: "tenant_feature_mappings",
          whereCondition: {
            tenant_id: tenantId,
            service_id: serviceId,
            is_active: 1,
          },
        };
        const mappingResponse = await fetchData(
          "getMasterList",
          mappingPayload
        );

        if (mappingResponse.data) {
          const initialCheckedState = {};
          serviceResponse.data.forEach((service) => {
            const mapping = mappingResponse.data.find(
              (mapping) => mapping.feature_id == service.id
            );
            initialCheckedState[service.id] = mapping
              ? mapping.map == 1
              : false;
          });
          setExistingMappings(mappingResponse.data);
          setCheckedServices(initialCheckedState);
          setOnGo(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTanentDropdownData = async () => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }
      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "tenant_company_name"
      );
      setTenantlist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getServiceDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "tenant_service_mappings",
        relations: [
          {
            module: "service_masters",
            moduleas: "service_masters",
          },
        ],
        whereCondition: {
          tenant_id: id,
          map: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      let dropdownResponseData = await dropdownData(
        responseData.data,
        "service_name",
        "join",
        "service_masters"
      );

      const uniqueOptions = dropdownResponseData[0].options.filter(
        (option, index, self) =>
          index ===
          self.findIndex(
            (o) => o.label === option.label && o.value === option.value
          )
      );

      // Update dropdownResponseData with unique options
      dropdownResponseData[0].options = uniqueOptions;

      setServicelist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOnchangeTenant = (selectedOption) => {
    setData([]);
    setOnGo(false);
    setTenantId("");
    setServiceId("");
    setSelectedService(null);
    setSelectedTenant(selectedOption);
    getServiceDropdownData(selectedOption?.value);
    setTenantId(selectedOption?.value);
  };

  const handleOnchangeService = (selectedOption) => {
    setSelectedService(selectedOption);
    setServiceId(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    const multipleData = Object.keys(checkedServices).map((featureId) => {
      const existingMapping = existingMappings.find(
        (mapping) => mapping.service_id === parseInt(featureId)
      );
      return {
        id: existingMapping ? existingMapping.id : null, // Include the ID if existing mapping is found
        tenant_id: tenantId,
        service_id: serviceId,
        feature_id: parseInt(featureId),
        map: checkedServices[featureId] ? 1 : 0,
        is_active: 1,
      };
    });

    // return false;
    var payload = {
      modelName: "tenant_feature_mappings",
      inputData: {
        multipleData: multipleData,
      },
    };

    const responseData = await fetchData("createAndUpdateMaster", payload);
    if (responseData.code === 1) {
      toast.success("Service Map saved successfully!");
      setTimeout(() => {
        navigate(0); // Navigate to the same page to refresh data
      }, 2000);
    } else {
      toast.error("Error While saving Service Map!");
    }
  };

  const handleCheckboxChange = (featureId) => {
    setCheckedServices({
      ...checkedServices,
      [featureId]: !checkedServices[featureId],
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="FEATURE ACTION MAPPING"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                      <Col md={4}>
                        <Label className="form-label">Tenant</Label>
                        <Select
                          value={selectedTenant}
                          onChange={handleOnchangeTenant}
                          options={tenantlist}
                          classNamePrefix="select1-selection"
                          isClearable
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Service</Label>
                        <Select
                          value={selectedService}
                          onChange={handleOnchangeService}
                          options={servicelist}
                          classNamePrefix="select2-selection"
                          noOptionsMessage={() => "no data found.."}
                          maxMenuHeight={200}
                        />
                      </Col>
                    </Row>

                    <Row
                      className="mt-3"
                      style={{
                        height: "55vh",
                        overflow: "auto",
                        // scrollbarWidth: "none",
                        // msOverflowStyle: "none",
                      }}>
                      {loading ? (
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        onGo && (
                          <>
                            <Col xl="12" className="mt-1">
                              <table className="table table-bordered">
                                <thead>
                                  <tr
                                    style={{
                                      backgroundColor: "#f2f2f2",
                                    }}>
                                    <th
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "16px",
                                        color: "balck",
                                      }}>
                                      Feature Action
                                    </th>
                                    <th
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "16px",
                                        color: "balck",
                                      }}>
                                      Map
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data && data.length > 0 ? (
                                    data.map((item) => (
                                      <tr key={item.id}>
                                        <td>{item.feature_name}</td>
                                        <td>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                              checkedServices[item.id] || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(item.id)
                                            }
                                            id={`tenantCheck${item.id}`}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="2">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </Col>
                          </>
                        )
                      )}
                    </Row>

                    {data.length > 0 && (
                      <Row className="mt-4">
                        <Col className="d-flex justify-content-center align-items-center mt-3">
                          <Button
                            color="primary"
                            type="submit"
                            className="waves-effect waves-light me-1">
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeatureActionMapping;
