import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { dropdownData, fetchData } from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import { formContentContainer, loaderOverlay } from "../../../Style/loaderOverly";

const HubCreate = ({ router }) => {
  const [breadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [selectedMegaHub, setSelectedMegaHub] = useState(null);
  const [megaHublist, setMegaHublist] = useState([]);
  const [megaHubError, setMegaHubError] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    hub_name: "",
    mega_hub_id: "",
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      getMegaHubDropdownData();
      let payload = {
        modelName: "hub_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id };
      }

      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          getMegaHubDropdownData(responseData.data[0].mega_hub_id);
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getMegaHubDropdownData = async (id) => {
    try {
      let payload = {
        modelName: "mega_hub_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "hub_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedMegaHub(element);
            }
          });
        }
        setMegaHublist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectMegaHubChange = (selectedOption) => {
    setSelectedMegaHub(selectedOption);
    setMegaHubError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      mega_hub_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedMegaHub ? setMegaHubError(true) : setMegaHubError(false);

    if (!selectedMegaHub || !formData.hub_name) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = {
        modelName: "hub_masters",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Hub saved successfully!");
        navigate("/hub-list");
      } else {
        toast.error("Error while saving Hub!");
        navigate("/hub-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/hub-list"); // Navigate to the desired route
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="CREATE HUB" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Mega Hub
                                <Asterisk />
                              </Label>
                              <Select
                                value={selectedMegaHub}
                                onChange={handleSelectMegaHubChange}
                                options={megaHublist}
                                classNamePrefix="select2-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                isClearable
                              />
                              {megaHubError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Mega Hub
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Hub Name
                                <Asterisk />
                              </Label>
                              <AvField
                                name="hub_name"
                                placeholder="Hub Name"
                                type="text"
                                errorMessage="Enter Hub Name"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.hub_name}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HubCreate;
